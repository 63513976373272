<template>
  <div class="order_tabs">
    <el-tabs
      v-model="activeName"
      stretch
      type="card"
      style="width: 1200px; margin: 0 auto"
      @tab-click="handleClick"
    >
      <el-tab-pane label="合同管理" name="first" :disabled="firstDisabled">
        <div class="first">
          <particulars></particulars>
        </div>
        <div class="content" v-if="isPublisher == 'true'">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">合同上传</div>
            </div>
            <div></div>
          </div>
          <div class="stencil">
            <el-upload
              class="upload-demo"
              action="#"
              :before-upload="beforeAvatarUpload"
              ref="upload"
              :on-change="handleChange"
              :limit="1000"
              :http-request="(params) => handleImageBeforeUpload(params, 1)"
              :file-list="fileList"
              :before-remove="beforeRemove"
              :on-exceed="handleExceed"
              v-if="
                (contrac.contractStatus == -1 || contrac.contractStatus == 0) &&
                contrac.contractType != 2 &&
                contrac.contractType != 3
              "
            >
              <el-button
                v-if="contrac.contractStatus == -1"
                size="small"
                class="hy-btn"
                >上传线下合同
              </el-button>
              <el-button
                style="background: none; border: none"
                v-if="contrac.contractStatus == 0"
                size="small"
                class="hy-btn"
                >重新上传线下合同</el-button
              >
            </el-upload>
            <div
              class="template hy-btn"
              @click="selectTem"
              v-if="contrac.contractStatus == 0 && contrac.contractType1 == 2"
            >
              重新上传
            </div>
            <el-button
              class="hy-btn"
              @click="addcontract(1)"
              v-if="
                contrac.contractStatus == -1 ||
                (contrac.contractStatus == 0 && contrac.contractType == 1)
              "
              style="margin-left: 10px"
              >提交合同</el-button
            >
            <div
              v-if="
                (contrac.contractStatus == 1 &&
                  contrac.annexInfo?.contractStatus == -1) ||
                contrac.annexInfo?.contractStatus == 1
              "
              style="line-height: 40px"
            >
              发布方已确认,等待参与方确认
            </div>
            <div
              v-if="
                (contrac.contractStatus == 2 &&
                  contrac.annexInfo?.contractStatus == -1) ||
                contrac.annexInfo?.contractStatus == 2
              "
              style="line-height: 40px"
            >
              参与方已确认,等待发布方确认
            </div>
            <div
              v-if="
                (contrac.contractStatus == 3 &&
                  contrac.annexInfo?.contractStatus == -1) ||
                contrac.annexInfo?.contractStatus == 3
              "
              style="margin-right: 10px; font-size: 20px; line-height: 40px"
            >
              双方已确认
            </div>
            <div style="margin-left: 10px; display: flex; align-items: center">
              <el-upload
                class="upload-demo"
                action="#"
                :before-upload="beforeAvatarUpload"
                ref="uploadContract"
                :on-change="handleChangeContract"
                :limit="1"
                :http-request="(params) => handleImageBeforeUpload(params, 2)"
                :file-list="fileList1"
                :before-remove="beforeRemove"
                :on-exceed="handleExceedContract"
                v-if="
                  (contrac.contractStatus == -1 ||
                    contrac.contractStatus == 0) &&
                  contrac.contractType != 2 &&
                  contrac.contractType != 1
                "
              >
                <el-button
                  v-if="contrac.contractStatus == -1"
                  size="small"
                  class="hy-btn"
                  >上传电子合同
                </el-button>
                <el-button
                  style="background: none; border: none"
                  v-if="contrac.contractStatus == 0"
                  size="small"
                  class="hy-btn"
                  >重新上传电子合同</el-button
                >
              </el-upload>
              <!-- <span style="color: #d0111a">(仅线上签章)</span> -->
            </div>
            <div
              style="cursor: pointer"
              class="stencil_preview button_on hy-btn"
              @click="accountClickss(contrac)"
              v-if="contrac.contractStatus != -1"
            >
              合同预览
            </div>
            <div
              style="cursor: pointer"
              class="template hy-btn"
              @click="selectTem"
              v-if="contrac.contractStatus == -1"
            >
              选择合同模板
            </div>
            <div
              class="template hy-btn"
              @click="countersign()"
              style="cursor: pointer"
              v-if="contrac.contractStatus == 0 || contrac.contractStatus == 2"
            >
              确认合同信息
            </div>
            <div
              style="cursor: pointer"
              class="stencil_preview button_on hy-btn"
              @click="accountClicksss()"
              v-if="contrac.contractStatus == 3 && isBidding"
            >
              附件预览
            </div>
            <div
              class="template hy-btn"
              @click="countersign('annex')"
              style="cursor: pointer"
              v-if="
                (contrac.annexInfo?.contractStatus == 0 ||
                  contrac.annexInfo?.contractStatus == 2) &&
                isBidding
              "
            >
              确认附件信息
            </div>
            <div
              v-if="contracOver && annexOver && isBidding"
              style="margin-left: 10px"
            >
              <el-button
                type="primary"
                :disabled="annexUrls.length >= 10"
                @click="handleAnnex"
                >附件上传</el-button
              >
            </div>
          </div>
          <div
            style="line-height: 40px; font-size: 20px"
            v-if="contrac.contractStatus == 0"
          >
            已上传
          </div>
        </div>
        <div class="content" v-if="isPublisher == 'false'">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">合同上传</div>
            </div>
            <div></div>
          </div>
          <div class="stencil">
            <div v-if="contrac.contractStatus == -1" style="line-height: 40px">
              未上传，请等待对方上传合同!
            </div>
            <div
              v-if="
                (contrac.contractStatus != -1 && contrac.contractStatus != 3) ||
                (contrac.annexInfo?.contractStatus != -1 &&
                  contrac.annexInfo?.contractStatus != 3)
              "
              style="margin-right: 10px; line-height: 40px"
            >
              已上传
            </div>
            <div
              style="margin-left: 10px; line-height: 40px"
              v-if="
                (contrac.contractStatus == 3 &&
                  contrac.annexInfo?.contractStatus == -1) ||
                (contrac.contractStatus == 3 &&
                  contrac.annexInfo?.contractStatus == 3)
              "
            >
              双方已确认
            </div>

            <div
              class="stencil_preview button_on hy-btn"
              @click="accountClickss(contrac)"
              v-if="contrac.contractStatus != -1"
            >
              合同预览
            </div>
            <div
              class="template hy-btn"
              @click="countersign()"
              style="cursor: pointer"
              v-if="contrac.contractStatus == 0 || contrac.contractStatus == 1"
            >
              确认合同信息
            </div>
            <div
              style="cursor: pointer"
              class="stencil_preview button_on hy-btn"
              @click="accountClicksss()"
              v-if="contrac.contractStatus == 3 && isBidding"
            >
              附件预览
            </div>
            <div
              class="template hy-btn"
              @click="countersign('annex')"
              style="cursor: pointer"
              v-if="
                (contrac.annexInfo?.contractStatus == 0 ||
                  contrac.annexInfo?.contractStatus == 1) &&
                isBidding
              "
            >
              确认附件信息
            </div>
            <div
              style="margin-left: 10px; font-size: 20px; line-height: 40px"
              v-if="contrac.contractStatus == 0"
            >
              发布方已上传,等待参与方确认
            </div>
          </div>
          <!-- <div
            style="margin-top: 20px; font-size: 30px"
            v-if="contrac.contractStatus == 0"
          >
            已上传
          </div> -->
        </div>
      </el-tab-pane>
      <!-- 在线签章 -->
      <el-tab-pane
        label="在线签章"
        name="three"
        :disabled="threeDisabled"
        v-if="signatureTab"
      >
        <div class="first">
          <particulars></particulars>
        </div>
        <div class="content"></div>
        <div class="uploading">
          <div class="uploading_all">
            <div class="shangchuang"></div>
            <div class="unpload">在线签章</div>
          </div>
          <div></div>
        </div>
        <div class="sing-btns">
          <div v-if="contrac.annexInfo?.contractStatus == -1">
            {{ contrac.signatureStatus | initContrac }}
          </div>
          <div v-else>
            {{
              contrac.annexInfo.signatureStatus == null
                ? 3
                : contrac.annexInfo.signatureStatus | initContrac
            }}
          </div>
          <el-button
            @click="confirmClicks"
            type="primary"
            v-if="signatureBtn"
            style="color: #ffffff"
            >{{
              contrac.annexInfo?.contractStatus == -1 ? "" : "附件"
            }}在线签章</el-button
          >
        </div>
      </el-tab-pane>
      <!-- 支付 -->
      <el-tab-pane
        label="支付管理"
        name="third"
        :disabled="thirdDisabled || !tabDisable"
      >
        <div class="first">
          <particulars @child-event="parentEvent" ref="parentref"></particulars>
        </div>
        <div class="content" v-if="isPublisher == 'true'">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">支付记录</div>
            </div>
          </div>
          <div style="margin-top: 30px">
            <el-table :data="tableDatas" border style="width: 100%" :key="tableKey">
              <el-table-column
                prop="createTime"
                align="center"
                min-width="120"
                label="支付时间"
              >
              </el-table-column>
              <el-table-column
                prop="payPrice"
                align="center"
                label="单价(元/吨)"
              >
              </el-table-column>
              <el-table-column
                prop="payCount"
                align="center"
                label="支付数量(吨)"
              >
              </el-table-column>
              <el-table-column
                prop="payAmount"
                align="center"
                label="支付金额(元)"
              >
              </el-table-column>

              <el-table-column prop="payType" align="center" label="支付方式">
              </el-table-column>

              <el-table-column
                prop="payBrand"
                v-if="isBidding"
                align="center"
                label="支付类型"
              >
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="收款状态"
                width="200"
              >
                <template slot-scope="scope">
                  <div type="text" size="small">
                    {{ scope.row.payStatus == "待支付" ? "待收款" : "已收款" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="付款凭证"
                width="200"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="accountClick(scope.row.voucherUrl)"
                    v-if="
                      scope.row.voucherUrl && scope.row.payType != '线上支付'
                    "
                    type="text"
                    size="small"
                  >
                    查看付款凭证</el-button
                  >
                  <el-upload
                    v-if="
                      !scope.row.voucherUrl && scope.row.payType != '线上支付'
                    "
                    class="upload-demo"
                    action="#"
                    multiple
                    :http-request="addupdateVoucher"
                    :show-file-list="false"
                    accept=".bmp,.jpg,.png,.jpeg,.pdf"
                    :limit="1"
                  >
                    <el-button
                      size="small"
                      type="primary"
                      @click="addVoucher(scope.row)"
                      >上传凭证
                    </el-button>
                  </el-upload>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                fixed="right"
                align="center"
                width="100"
              >
                <template slot-scope="scope">
                  <el-popover placement="right" width="500" trigger="click" v-if="isListingSales">
                    <el-table :data="scope.row?.paymentDetailList || []">
                      <el-table-column
                        width="150"
                        property="goodsName"
                        label="商品名称"
                      ></el-table-column>
                      <el-table-column
                        property="goodsPrice"
                        label="单价"
                      ></el-table-column>
                      <el-table-column
                        property="number"
                        label="数量"
                      ></el-table-column>
                      <el-table-column property="number" label="金额">
                        <template slot-scope="scope">
                          <span>{{
                            (scope.row.goodsPrice *
                              1000000 *
                              scope.row.number) /
                            1000000
                          }}</span>
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-button
                      slot="reference"
                      type="text"
                      style="background: transparent; margin-left: 10px"
                      size="small"
                      v-if="scope.row?.paymentDetailList && isListingSales"
                      >查看明细</el-button
                    >
                  </el-popover>
                </template>
              </el-table-column>
            </el-table>
            <div class="pattern">
              <div @click="flineFormclick(1)" class="hy-btn">线上支付</div>
              <div @click="flineFormclick(2)" class="hy-btn">线下支付</div>
              <div class="hy-btn">承兑付款</div>
            </div>
          </div>
        </div>
        <div class="content" v-if="isPublisher == 'false'">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">支付记录</div>
            </div>
          </div>
          <div style="margin-top: 30px">
            <el-table :data="tableDatas" border style="width: 100%" :key="tableKey">
              <el-table-column
                prop="createTime"
                align="center"
                label="支付时间"
                width="200"
              >
              </el-table-column>
              <el-table-column prop="payPrice" align="center" label="单价(元)">
              </el-table-column>
              <el-table-column
                prop="payCount"
                align="center"
                label="支付数量(吨)"
              >
              </el-table-column>
              <el-table-column
                prop="payAmount"
                align="center"
                label="支付金额(元)"
              >
              </el-table-column>

              <el-table-column prop="payType" align="center" label="支付方式">
              </el-table-column>
              <el-table-column fixed="right" align="center" label="收款状态">
                <template slot-scope="scope">
                  <div type="text" size="small">
                    {{ scope.row.payStatus == "待支付" ? "待收款" : "已收款" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="payBrand"
                v-if="isBidding"
                align="center"
                label="支付类型"
              >
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="付款凭证"
                width="200"
                prop="payStatus"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="accountClick(scope.row.voucherUrl)"
                    v-if="scope.row.payType != '线上支付'"
                    :disabled="!scope.row.voucherUrl"
                    type="text"
                    size="small"
                    >查看付款凭证</el-button
                  >
                </template>
              </el-table-column>

              <el-table-column
                fixed="right"
                align="center"
                label="操作"
                width="200"
                prop="payStatus"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="addconfirm(scope.row)"
                    v-if="scope.row.payType != '线上支付'"
                    :disabled="scope.row.payStatus == '支付成功'"
                    type="text"
                    size="small"
                    >确认金额已到账
                  </el-button>
                  <el-popover placement="right" width="500" trigger="click" v-if="isListingSales">
                    <el-table :data="scope.row?.paymentDetailList || []">
                      <el-table-column
                        width="150"
                        property="goodsName"
                        label="商品名称"
                      ></el-table-column>
                      <el-table-column
                        property="goodsPrice"
                        label="单价"
                      ></el-table-column>
                      <el-table-column
                        property="number"
                        label="数量"
                      ></el-table-column>
                      <el-table-column property="number" label="金额">
                        <template slot-scope="scope">
                          <span>{{
                            (scope.row.goodsPrice *
                              1000000 *
                              scope.row.number) /
                            1000000
                          }}</span>
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-button
                      slot="reference"
                      type="text"
                      style="background: transparent; margin-left: 10px"
                      size="small"
                      v-if="scope.row?.paymentDetailList && isListingSales"
                      >查看明细</el-button
                    >
                  </el-popover>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
      <!-- 运输 -->
      <el-tab-pane
        label="运输管理"
        name="fourth"
        :disabled="fourthDisabled || !tabDisable"
        v-if="!isLogistics"
      >
        <div class="first">
          <particulars></particulars>
        </div>
        <div class="content">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">物流运输</div>
            </div>
            <div style="display: flex">
              <div
                class="invoice hy-btn"
                @click="carriageClick"
                v-if="
                  (isPublisher == 'true' && deliveryMode == 2) ||
                  (isPublisher == 'false' && deliveryMode == 1)
                "
              >
                发起运输
              </div>
              <div
                style="margin-left: 10px"
                class="invoices hy-btn"
                @click="carriageClicks"
                v-if="
                  (isPublisher == 'true' && deliveryMode == 2) ||
                  (isPublisher == 'false' && deliveryMode == 1)
                "
              >
                服务商运输
              </div>
            </div>
          </div>
          <div style="margin-top: 30px">
            <el-table :data="tralist" border style="width: 100%">
              <el-table-column
                prop="createTime"
                align="center"
                label="运输时间"
              >
              </el-table-column>
              <el-table-column
                prop="trafficCount"
                align="center"
                label="运输数量(吨)"
              >
              </el-table-column>
              <el-table-column align="center" label="运输方式">
                <template slot-scope="scope">
                  {{ scope.row.trafficWay | formatType }}
                </template>
              </el-table-column>
              <el-table-column prop="startPlace" align="center" label="起始地">
              </el-table-column>
              <el-table-column prop="destPlace" align="center" label="目的地">
              </el-table-column>
              <el-table-column prop="contacts" align="center" label="联系人">
              </el-table-column>
              <el-table-column
                prop="contactInfo"
                align="center"
                label="联系方式"
              >
              </el-table-column>
              <el-table-column fixed="right" align="center" label="运输凭证">
                <template slot-scope="scope">
                  <el-button
                    @click="trafficDocUrlsClick(scope.row.trafficDocUrls)"
                    :disabled="scope.row.trafficDocUrls.length == 0"
                    type="text"
                    size="small"
                    >查看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
      <!-- 结算 -->
      <el-tab-pane
        label="结算管理"
        name="five"
        :disabled="fiveDisabled || !tabDisable"
      >
        <div class="first">
          <particulars></particulars>
        </div>
        <div class="content" v-if="isPublisher == 'false'">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">货物结算</div>
            </div>
            <div>
              <span v-if="isBidding">预付款余额：{{ prepaymentBalance }}</span>
              <el-button
                v-if="isPublisher == 'false' && isBidding"
                style="margin-left: 10px"
                @click="receivingVisible = true"
                size="small"
                >发起结算</el-button
              >
              <el-button
                v-if="isPublisher == 'false' && isBidding"
                style="margin-left: 10px"
                size="small"
                @click="returnSign"
                >退还预付款</el-button
              >
              <el-button size="small" @click="exportSignData"
                >导出结算数据</el-button
              >
            </div>
          </div>
          <div style="margin-top: 30px">
            <el-table :data="signlist" border style="width: 100%">
              <el-table-column
                prop="createTime"
                align="center"
                label="结算时间"
              >
              </el-table-column>
              <el-table-column prop="signCount" align="center" label="结算数量">
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="结算状态"
                width="100"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.deliverStatus == 0"> 待结算 </span>
                  <span v-if="scope.row.deliverStatus == 1"> 已结算 </span>
                  <span v-if="scope.row.deliverStatus == 2">
                    等待对方结算
                  </span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="结算方式" v-if="isBidding">
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.settleWay == 1 ? "预付款结算" : "其他" }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="settleAmount"
                align="center"
                label="结算金额"
                v-if="isBidding"
              >
              </el-table-column>
              <el-table-column
                align="center"
                label="结算单据"
                width="200"
                prop="payStatus"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      :disabled="!scope.row.settleFileList.length"
                      @click="
                        accountClick(scope.row.settleFileList, 'settlement')
                      "
                      type="text"
                      size="small"
                      >查看
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="煤质检验单"
                width="200"
                prop="payStatus"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      :disabled="!scope.row.qualityFileList.length"
                      @click="
                        accountClick(scope.row.qualityFileList, 'settlement')
                      "
                      type="text"
                      size="small"
                      >查看
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="结算明细"
                width="100"
                prop="payStatus"
                v-if="isBidding"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      @click="signDetail(scope.row.signId)"
                      type="text"
                      size="small"
                      >查看
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="操作"
                width="200"
              >
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.deliverStatus == 0"
                    :disabled="scope.row.deliverStatus != 0"
                    @click="receivingClickS(scope.row)"
                    type="text"
                    size="small"
                    >确认结算</el-button
                  >
                  <el-button
                    v-if="scope.row.deliverStatus == 0 && isBidding"
                    :disabled="scope.row.deliverStatus != 0"
                    @click="receivingCancel(scope.row)"
                    type="text"
                    size="small"
                    >取消结算</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="content" v-if="isPublisher == 'true'">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">货物结算</div>
            </div>
            <div>
              <span v-if="isBidding">预付款余额：{{ prepaymentBalance }}</span>
              <el-button size="small" @click="exportSignData"
                >导出结算数据</el-button
              >
            </div>
          </div>
          <div style="margin-top: 30px">
            <el-table :data="signlist" border style="width: 100%">
              <el-table-column
                prop="createTime"
                align="center"
                label="结算时间"
              >
              </el-table-column>
              <el-table-column
                prop="enterpriseName"
                align="center"
                label="发起方名称"
                v-if="isPublisher == 'true' && isBidding"
              >
              </el-table-column>
              <el-table-column prop="signCount" align="center" label="结算数量">
              </el-table-column>
              <el-table-column align="center" label="结算状态" width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.deliverStatus == 0"> 待结算 </span>
                  <span v-if="scope.row.deliverStatus == 1"> 已结算 </span>
                  <span v-if="scope.row.deliverStatus == 2">
                    等待对方结算
                  </span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="结算方式" v-if="isBidding">
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.settleWay == 1 ? "预付款结算" : "其他" }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="settleAmount"
                align="center"
                label="结算金额"
                v-if="isBidding"
              >
              </el-table-column>
              <el-table-column
                align="center"
                label="结算单据"
                width="200"
                prop="payStatus"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      :disabled="!scope.row.settleFileList.length"
                      @click="
                        accountClick(scope.row.settleFileList, 'settlement')
                      "
                      type="text"
                      size="small"
                      >查看
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="煤质检验单"
                width="200"
                prop="payStatus"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      :disabled="!scope.row.qualityFileList.length"
                      @click="
                        accountClick(scope.row.qualityFileList, 'settlement')
                      "
                      type="text"
                      size="small"
                      >查看
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="结算明细"
                width="100"
                prop="payStatus"
                v-if="isBidding"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      @click="signDetail(scope.row.signId)"
                      type="text"
                      size="small"
                      >查看
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="操作"
                width="200"
                v-if="isBidding"
              >
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.deliverStatus == 0"
                    :disabled="scope.row.deliverStatus != 0"
                    @click="receivingClickS(scope.row)"
                    type="text"
                    size="small"
                    >确认结算</el-button
                  >
                  <el-button
                    v-if="scope.row.deliverStatus == 0 && isBidding"
                    :disabled="scope.row.deliverStatus != 0"
                    @click="receivingCancel(scope.row)"
                    type="text"
                    size="small"
                    >取消结算</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div style="float: right; margin: 20px">
              <button class="hy-btn" @click="receivingVisible = true">
                发起结算
              </button>
            </div>

            <div class="receiving"></div>
          </div>
        </div>
      </el-tab-pane>
      <!-- 开票 -->
      <el-tab-pane
        label="开票管理"
        name="second"
        :disabled="secondDisabled || !tabDisable"
      >
        <div class="first">
          <particulars></particulars>
        </div>
        <div class="content" v-if="isPublisher == 'false'">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">开票</div>
            </div>
            <div class="invoice hy-btn" @click="sponsorClick">发起开票</div>
          </div>
          <div style="margin-top: 30px">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column
                prop="createTime"
                align="center"
                label="开票时间"
              >
              </el-table-column>
              <el-table-column
                prop="invoiceCount"
                align="center"
                label="开票数量"
              >
              </el-table-column>
              <el-table-column
                prop="invoiceAmount"
                align="center"
                label="开票金额"
              >
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="发票单据"
                width="100"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.invoiceUrlList">
                    <el-button
                      @click="accountClickList(scope.row)"
                      type="text"
                      size="small"
                    >
                      查看</el-button
                    >
                  </span>
                  <span v-else> 无发票信息 </span>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="操作"
                width="100"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.confirmStatus != 1">
                    <el-button type="text" size="small">未确认</el-button>
                  </span>
                  <span v-else>
                    <el-button type="text" size="small">已确认</el-button>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="content" v-if="isPublisher == 'true'">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">开票</div>
            </div>
          </div>
          <div style="margin-top: 30px">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column
                prop="createTime"
                align="center"
                label="开票时间"
              >
              </el-table-column>
              <el-table-column
                prop="invoiceCount"
                align="center"
                label="开票数量"
              >
              </el-table-column>
              <el-table-column
                prop="invoiceAmount"
                align="center"
                label="开票金额"
              >
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="发票单据"
                width="100"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.invoiceUrlList">
                    <el-button
                      @click="accountClickList(scope.row)"
                      type="text"
                      size="small"
                    >
                      查看</el-button
                    >
                  </span>
                  <span v-else> 无发票信息 </span>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="操作"
                width="100"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.confirmStatus != 1">
                    <el-button
                      @click="noteconfirm(scope.row)"
                      type="text"
                      size="small"
                      >收票确认
                    </el-button>
                  </span>
                  <span v-else>
                    <el-button type="text" size="small">已确认</el-button>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- <div class="receiving">
						<div class="sign hy-btn" @click="confirmClick">合同整体已完成</div>
					</div> -->
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 退还预付款 -->
    <div>
      <el-dialog
        title="退还预付款"
        width="400px"
        :visible.sync="returnSignDiolog"
      >
        <div style="display: flex; align-items: flex-start">
          上传退款凭证：<el-upload
            class="upload-demo"
            action="#"
            ref="returnSignUpload"
            :http-request="(params) => handleImageBeforeUpload(params, 6)"
            accept=".bmp,.jpg,.png,.jpeg,.pdf"
            :limit="1000"
            :file-list="returnSignfileList"
            :on-exceed="handleExceedel"
            multiple
            :before-remove="beforeRemoves"
            :on-remove="removereturnSignFile"
          >
            <el-button type="primary">点击上传</el-button>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="returnSignDiolog = false">取 消</el-button>
          <el-button type="primary" @click="submitReturnSign">提交</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 结算详情 -->
    <div>
      <el-dialog title="结算明细" width="1000px" :visible.sync="signDiolog">
        <div>
          <el-row
            v-for="(item, index) in signDetailData.settleDetailList"
            :key="index"
          >
            <el-col :span="4">结算商品名称:</el-col>
            <el-col :span="4">{{ item.goodsName }}</el-col>
            <el-col :span="4">结算数量:</el-col>
            <el-col :span="4">{{ item.number }}</el-col>
            <el-col :span="4">结算单价:</el-col>
            <el-col :span="4">{{ item.goodsPrice }}</el-col>
          </el-row>
          <el-row style="margin-top: 30px">
            <el-col :span="4">结算单据:</el-col>
            <el-col :span="8">
              <el-button
                class="el-button el-button--small"
                @click="fileView(signDetailData?.settleFileList, 1)"
                v-if="signDetailData?.settleFileList?.length"
              >
                点击查看</el-button
              >
            </el-col>
            <el-col :span="4">煤质检验单:</el-col>
            <el-col :span="8">
              <el-button
                class="el-button el-button--small"
                @click="fileView(signDetailData?.qualityFileList, 2)"
                v-if="signDetailData?.qualityFileList?.length"
              >
                点击查看</el-button
              >
            </el-col>
          </el-row>
          <el-row style="margin-top: 30px">
            <el-col :span="4">结算总数量:</el-col>
            <el-col :span="8">{{ signDetailData.signCount }}</el-col>
            <el-col :span="4">结算总金额:</el-col>
            <el-col :span="8">{{ signDetailData.settleAmount }}</el-col>
          </el-row>
        </div>
        <el-dialog
          title="预览"
          width="30%"
          append-to-body
          :visible.sync="detailReceivingFileView"
        >
          <div class="block">
            <el-carousel trigger="click" :autoplay="false" height="300px">
              <el-carousel-item
                v-for="(item, index) in receivingFilesDetail"
                :key="index"
                class="image_item"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <el-button
                  @click="examinecontract(item.storageName)"
                  v-if="
                    item.storageName.slice(-4) == '.pdf' ||
                    item.storageName.slice(-4) == '.PDF'
                  "
                >
                  点击查看PDF文件<br /><br /><br />{{
                    item.storageName
                  }}</el-button
                >
                <el-image
                  v-else
                  :src="baseUrl + 'third/third/ossGetFile/' + item.storageName"
                  :preview-src-list="[
                    baseUrl + 'third/third/ossGetFile/' + item.storageName,
                  ]"
                ></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
        </el-dialog>
      </el-dialog>
    </div>
    <div>
      <el-dialog title="附件上传" width="30%" :visible.sync="annexUploadDiolog">
        <div class="annexUploadDiolog-content">
          <div>请选择上传方式:</div>
          <div style="margin-left: 10px">
            <div>
              <el-upload
                ref="annexUpload1"
                :disabled="annexUrls.length >= 10"
                class="upload-demo"
                action="#"
                accept=".png,.jpg,.pdf"
                :before-remove="beforeRemove"
                :before-upload="beforeUpload"
                :on-change="handleAnnex1"
                :on-remove="removeAnnexFile"
                :http-request="(params) => handleImageBeforeUpload(params, 3)"
                multiple
                :limit="10"
                :on-exceed="handleExceeds"
                :file-list="fileLists"
              >
                <el-button type="text" :disabled="annexUrls.length >= 10"
                  >点击上传线下文件</el-button
                >
              </el-upload>
            </div>
            <div v-if="isAnnex1">
              <el-upload
                ref="annexUpload3"
                :disabled="annexUrls.length >= 10"
                class="upload-demo"
                action="#"
                accept=".png,.jpg,.pdf"
                :before-remove="beforeRemove"
                :before-upload="beforeUpload"
                :on-change="handleAnnex2"
                :http-request="(params) => handleImageBeforeUpload(params, 4)"
                multiple
                :limit="1"
                :on-exceed="handleExceeds1"
                :file-list="fileLists1"
              >
                <el-button type="text" :disabled="annexUrls.length >= 10"
                  >点击上传电子合同</el-button
                >
              </el-upload>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="annexUploadDiolog = false">取 消</el-button>
          <el-button type="primary" @click="addcontract(3)">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 签收货物 -->
    <div>
      <el-dialog
        title=""
        :width="isBidding ? '1000px' : '500px'"
        :visible.sync="receivingVisible"
        @close="closeChan"
      >
        <el-form
          :model="receivingForm"
          ref="receivingForm"
          :rules="receivingFormRule"
          ><div style="display: flex; flex-wrap: wrap">
            <div
              class="line goods-list"
              v-for="(goods, index) in receivingForm.settleDetailList"
              :key="goods.key"
            >
              <el-form-item
                label=""
                label-width="0px"
                :prop="'settleDetailList.' + index + '.categoryId'"
                :rules="{
                  required: true,
                  message: '请选择商品名',
                  trigger: 'change',
                }"
                v-if="isBidding"
              >
                <el-select
                  v-model="receivingForm.settleDetailList[index].categoryId"
                  placeholder="请选择商品名"
                  style="width: 150px"
                >
                  <el-option
                    v-for="(item, goodsIndex) in goodsCategoryList"
                    :key="item.goodsType + goodsIndex"
                    :label="item.goodsName"
                    :value="item.categoryId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="结算数量:"
                label-width="140px"
                :prop="'settleDetailList.' + index + '.number'"
                :rules="{
                  required: true,
                  message: '请输入结算数量',
                  trigger: 'blur',
                }"
                v-if="isBidding"
              >
                <el-input
                  :placeholder="'请输入结算数量(吨)'"
                  style="width: 150px"
                  maxlength="20"
                  v-model.trim="receivingForm.settleDetailList[index].number"
                  autocomplete="off"
                  min="0.1"
                  @input="
                    formatFloat1(
                      receivingForm.settleDetailList[index],
                      'number',
                      'count',
                      4
                    )
                  "
                ></el-input>
              </el-form-item>
              <el-form-item
                label="结算单价:"
                label-width="140px"
                :prop="'settleDetailList.' + index + '.goodsPrice'"
                :rules="{
                  required: true,
                  message: '请输入结算单价',
                  trigger: 'blur',
                }"
                v-if="isBidding"
              >
                <el-input
                  :placeholder="'请输入结算单价(吨)'"
                  style="width: 150px"
                  maxlength="20"
                  v-model.trim="
                    receivingForm.settleDetailList[index].goodsPrice
                  "
                  autocomplete="off"
                  min="0.1"
                  @input="
                    formatFloat1(
                      receivingForm.settleDetailList[index],
                      'goodsPrice',
                      'price',
                      4
                    )
                  "
                ></el-input>
                <el-button
                  v-if="goods.key == 0"
                  style="margin-left: 10px"
                  @click="addGoodes(4)"
                  >增加</el-button
                >
                <el-button
                  v-if="goods.key != 0"
                  style="margin-left: 10px"
                  @click="delGoods(goods, 4)"
                  >删除</el-button
                >
              </el-form-item>
            </div>
          </div>
          <el-form-item
            label="结算数量"
            prop="signCount"
            :label-width="formLabelWidth"
            v-if="!isBidding"
          >
            <el-input
              v-model="receivingForm.signCount"
              autocomplete="off"
              @input="receivingFormSignCount('signCount')"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="请选择结算类型"
            :label-width="formLabelWidth"
            v-if="isBidding"
          >
            <el-radio v-model="receivingForm.settleWay" :label="1"
              >预付款余额结算</el-radio
            >
            <el-radio v-model="receivingForm.settleWay" :label="0"
              >其他</el-radio
            >
          </el-form-item>
          <el-form-item label="结算单据" :label-width="formLabelWidth" required>
            <el-row>
              <el-col :span="8">
                <el-upload
                  class="upload-demo"
                  action="#"
                  :http-request="(param) => handleImageBeforeUpload(param, 7)"
                  accept=".bmp,.jpg,.png,.jpeg,.pdf"
                  multiple
                  ref="uploadFileReceivingInvoice"
                  :before-remove="beforeRemoves"
                  :limit="isBidding ? 5 : 1"
                  :on-exceed="fileExceed"
                  :file-list="receivingInvoiceUrl"
                  :on-remove="removeInvoiceUrlFile"
                  :on-change="handleReceivingInvoiceUrlFileChange"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-col>
              <el-col :span="16">
                <el-button
                  class="el-button el-button--small"
                  @click="handleReceivingFileView(1)"
                >
                  点击查看</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="煤质检验单" :label-width="formLabelWidth">
            <el-row>
              <el-col :span="8">
                <el-upload
                  class="upload-demo"
                  action="#"
                  :http-request="(param) => handleImageBeforeUpload(param, 8)"
                  accept=".bmp,.jpg,.png,.jpeg,.pdf"
                  multiple
                  ref="uploadFileInspect"
                  :before-remove="beforeRemoves"
                  :limit="isBidding ? 5 : 1"
                  :on-exceed="fileExceed"
                  :file-list="inspectFile"
                  :on-remove="removeInspectFile"
                  :on-change="handleInspectFileFileChange"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-col>
              <el-col :span="16">
                <el-button
                  class="el-button el-button--small"
                  @click="handleReceivingFileView(2)"
                >
                  点击查看</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
          <div style="display: flex">
            <el-form-item
              label="结算总数量"
              v-if="isBidding"
              :label-width="formLabelWidth"
            >
              {{ signCountTotal }}
            </el-form-item>
            <el-form-item
              label="结算总金额"
              v-if="isBidding"
              :label-width="formLabelWidth"
            >
              {{ settleAmountTotal }}
            </el-form-item>
          </div>
        </el-form>
        <el-dialog
          title="预览"
          width="30%"
          append-to-body
          :visible.sync="receivingFileView"
        >
          <div class="block">
            <el-carousel trigger="click" :autoplay="false" height="300px">
              <el-carousel-item
                v-for="(item, index) in receivingFiles"
                :key="index"
                class="image_item"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <el-button
                  @click="examinecontract(item.storageName)"
                  v-if="
                    item.storageName.slice(-4) == '.pdf' ||
                    item.storageName.slice(-4) == '.PDF'
                  "
                >
                  点击查看PDF文件<br /><br /><br />{{
                    item.storageName
                  }}</el-button
                >
                <el-image
                  v-else
                  :src="baseUrl + 'third/third/ossGetFile/' + item.storageName"
                  :preview-src-list="[
                    baseUrl + 'third/third/ossGetFile/' + item.storageName,
                  ]"
                ></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
        </el-dialog>
        <div slot="footer" class="dialog-footer">
          <el-button @click="receivingVisible = false">取 消</el-button>
          <el-button type="primary" @click="receivingClick">发起结算</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 选择合同模板 -->
    <div>
      <el-dialog title="选择合同模板" :visible.sync="dialogTableVisible">
        <el-table
          :data="gridData"
          border
          ref="tb"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" v-model="radio" width="55">
          </el-table-column>
          <el-table-column
            property="contractNum"
            label="合同编号"
            align="center"
          ></el-table-column>
          <el-table-column
            property="contractName"
            label="合同名称"
            align="center"
          ></el-table-column>
          <el-table-column
            property="createTime"
            align="center"
            label="提交时间"
          ></el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                @click="examineClick(scope.row.contractUrl)"
                type="text"
                size="small"
                >查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogTableVisible = false">取 消</el-button>
          <el-button type="primary" @click="CurrentRowClick()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!--  -->
    <div>
      <el-dialog
        title="完善合同"
        :visible.sync="dialogTableVisibles"
        width="800px"
      >
        <el-form
          label-width="150px"
          :inline="true"
          :rules="onlineInforules"
          ref="onlineInfo"
          :model="onlineInfo"
        >
          <el-form-item label="合同编号" prop="contractNo">
            <el-input
              v-model="onlineInfo.contractNo"
              placeholder="请输入合同编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="供方" prop="supplier">
            <el-input
              v-model="onlineInfo.supplier"
              placeholder="请输入供方"
            ></el-input>
          </el-form-item>
          <el-form-item label="需方" prop="demander">
            <el-input
              v-model="onlineInfo.demander"
              placeholder="请输入需方"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="supplierContact">
            <div style="display: flex" class="input_size">
              <el-input
                v-model="onlineInfo.supplierContact"
                placeholder="联系人"
              ></el-input>
              <el-input
                v-model="onlineInfo.supplierPhone"
                style="margin-left: 10px"
                placeholder="联系方式"
              >
              </el-input>
            </div>
          </el-form-item>
          <el-form-item
            label="联系人"
            class="input_size"
            prop="demanderContact"
          >
            <div style="display: flex" class="input_size">
              <el-input
                v-model="onlineInfo.demanderContact"
                placeholder="联系人"
              ></el-input>
              <el-input
                v-model="onlineInfo.demanderPhone"
                style="margin-left: 10px"
                placeholder="联系方式"
              >
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="运输方式" prop="transportType">
            <el-select
              v-model="onlineInfo.transportType"
              placeholder="请选择运输方式"
            >
              <el-option label="铁路" value="1"></el-option>
              <el-option label="公路" value="2"></el-option>
              <el-option label="水路" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="有效开始日期" prop="effectiveStartDate">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="onlineInfo.effectiveStartDate"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="有效结束日期" prop="effectiveEndDate">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="onlineInfo.effectiveEndDate"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="交货方式" prop="deliveryType">
            <el-select
              v-model="onlineInfo.deliveryType"
              placeholder="请选择交货方式"
            >
              <el-option label="平仓" value="1"></el-option>
              <el-option label="场地" value="2"></el-option>
              <el-option label="车板" value="3"></el-option>
              <el-option label="舱底" value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="签订地" prop="signingPlace">
            <el-input
              v-model="onlineInfo.signingPlace"
              placeholder="请输入签订地"
            ></el-input>
          </el-form-item>
          <el-form-item label="付款方式" prop="payType">
            <el-select
              v-model="onlineInfo.payType"
              placeholder="请选择付款方式"
            >
              <el-option label="现汇" value="1"></el-option>
              <el-option label="承兑" value="2"></el-option>
              <el-option label="现汇+承兑" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="数量(吨)" prop="number">
            <el-input
              v-model="onlineInfo.number"
              placeholder="请输入数量"
            ></el-input>
          </el-form-item>
          <el-form-item label="配送方式" prop="distributionType">
            <el-select
              v-model="onlineInfo.distributionType"
              placeholder="请选择配送方式"
            >
              <el-option label="自提" value="1"></el-option>
              <el-option label="送货" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="总额(元)" prop="totalAmount">
            <el-input
              v-model="onlineInfo.totalAmount"
              placeholder="请输入总额"
            ></el-input>
          </el-form-item>
          <el-form-item label="交换地" prop="deliveryAddress">
            <el-input
              v-model="onlineInfo.deliveryAddress"
              placeholder="请输入交换地"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-form
          label-width="150px"
          :inline="true"
          :rules="goodsInfoListrules"
          ref="goodsInfoList"
          :model="goodsInfoList"
        >
          <el-form-item label="产品名称" prop="coalName">
            <el-input
              v-model="goodsInfoList.coalName"
              placeholder="请输入产品名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="产品单价" prop="price">
            <el-input
              v-model="goodsInfoList.price"
              placeholder="请输入产品单价"
            ></el-input>
          </el-form-item>
          <el-form-item label="产品数量" prop="quantity">
            <el-input
              v-model="goodsInfoList.quantity"
              placeholder="请输入产品数量"
            ></el-input>
          </el-form-item>
          <el-form-item label="产品总价" prop="totalAmount">
            <el-input
              v-model="goodsInfoList.totalAmount"
              placeholder="请输入产品总价"
            ></el-input>
          </el-form-item>
          <el-form-item label="产品指标" prop="quota">
            <el-input
              v-model="goodsInfoList.quota"
              placeholder="请输入产品指标"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: center">
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button @click="dialogTableVisibles = false">取消</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 发起开票 -->
    <div>
      <el-dialog title="开票" width="500px" :visible.sync="dialogFormVisible">
        <el-form :model="form" ref="invoiceForm" :rules="formRules">
          <div style="display: flex; flex-wrap: wrap">
            <div
              class="line goods-list"
              v-for="(goods, index) in form.invoiceDetailList"
              :key="goods.key"
            >
              <el-form-item
                label="开票商品"
                :label-width="formLabelWidth"
                :prop="'invoiceDetailList.' + index + '.categoryId'"
                :rules="{
                  required: true,
                  message: '请选择商品名',
                  trigger: 'change',
                }"
                v-if="isBidding"
              >
                <el-select
                  v-model="form.invoiceDetailList[index].categoryId"
                  placeholder="请选择商品名"
                  style="width: 150px"
                >
                  <el-option
                    v-for="(item, goodsIndex) in goodsCategoryList"
                    :key="item.goodsType + goodsIndex"
                    :label="item.goodsName"
                    :value="item.categoryId"
                  >
                  </el-option>
                </el-select>
                <el-button
                  v-if="goods.key == 0"
                  :disabled="
                    form.invoiceDetailList.length >= goodsCategoryList.length
                  "
                  style="margin-left: 10px"
                  @click="addGoodes(5)"
                  >增加</el-button
                >
                <el-button
                  v-if="goods.key != 0"
                  style="margin-left: 10px"
                  @click="delGoods(goods, 5)"
                  >删除</el-button
                >
              </el-form-item>
            </div>
          </div>
          <el-form-item
            label="开票数量："
            :label-width="formLabelWidth"
            prop="invoiceCount"
          >
            <el-input
              placeholder="请输入开票数量"
              v-model.trim="form.invoiceCount"
              autocomplete="off"
              maxlength="20"
              style="width: 120px"
              @input="receivingFormSignCount('invoiceCount')"
            >
            </el-input>
            <span style="margin-left: 20px">吨</span>
          </el-form-item>
          <el-form-item
            label="开票金额："
            :label-width="formLabelWidth"
            prop="invoiceAmount"
          >
            <el-input
              placeholder="请输入开票金额"
              v-model.trim="form.invoiceAmount"
              autocomplete="off"
              maxlength="20"
              @input="receivingFormSignCount('invoiceAmount')"
              style="width: 120px"
            >
            </el-input
            ><span style="margin-left: 20px">元</span>
          </el-form-item>
          <el-form-item
            label="发票单据："
            :label-width="formLabelWidth"
            prop="invoiceUrl"
          >
            <el-row>
              <el-col :span="8">
                <el-upload
                  class="upload-demo"
                  action="#"
                  accept=".bmp,.jpg,.png,.jpeg,.pdf"
                  :http-request="addsettleUrl"
                  :show-file-list="false"
                  multiple
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-col>

              <el-col :span="16" v-if="form.invoiceUrlList.length > 0">
                <!-- <el-button class="el-button el-button--small" @click="examinecontract(form.invoiceUrl)"
									v-if="form.invoiceUrl && ( form.invoiceUrl.slice(-4) == '.pdf' || form.invoiceUrl.slice(-4) == '.PDF' )">
									点击查看PDF文件<br/><br/>{{ form.invoiceUrl }}</el-button>
								<el-image v-else-if="form.invoiceUrl" style="width: 100px"
									:src="fileBaseUrl + form.invoiceUrl"
									:preview-src-list="[fileBaseUrl + form.invoiceUrl]"></el-image> -->
                <el-button @click="invoiceUrlVis = true">查看</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addinvoice">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-dialog title="预览" width="30%" :visible.sync="invoiceUrlVis">
        <div
          class="block"
          v-for="(item, index) in form.invoiceUrlList"
          :key="index"
          style="display: flex; justify-content: center"
        >
          <el-button
            @click="examinecontract(item)"
            v-if="
              (item && item.slice(-4) == '.pdf') ||
              (item && item.slice(-4) == '.PDF')
            "
          >
            点击查看PDF文件<br /><br /><br />{{ item }}</el-button
          >
          <el-image
            v-else
            :src="baseUrl + 'third/third/ossGetFile/' + item"
            :preview-src-list="[baseUrl + 'third/third/ossGetFile/' + item]"
          ></el-image>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <el-button type="primary" @click="invoiceUrlVisFlse">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <el-dialog title="选择服务商" :visible.sync="productlist">
      <div>
        <div
          class="productItem"
          v-for="(item, index) in tableList"
          :key="index"
        >
          <div style="display: flex">
            <div class="produitem_img">
              <img
                :src="baseUrl + 'third/third/ossGetFile/' + item.mainGraph"
                alt=""
              />
            </div>
            <div style="margin-left: 20px; color: #40474e; font-weight: 500">
              <div style="font-size: 20px">{{ item.name }}</div>
              <div style="display: flex; margin-top: 13px; font-size: 14px">
                <div>服务类别：{{ item.serviceType }}</div>
                <div style="margin: 0 10px">|</div>
                <div v-if="item.price != null">
                  单价：{{ item.price }}{{ item.unit }}/吨
                </div>
                <div v-else>单价：面议</div>
              </div>
              <div style="margin-top: 20px; font-size: 12px; color: #787b80">
                {{ item.createTime }}
              </div>
            </div>
          </div>

          <div>
            <div class="onsale" style="">销售中</div>
            <el-button
              type="primary"
              style="margin-top: 40px"
              @click="transmitClick(item)"
              >确认发送</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 服务商运输 -->
    <div>
      <el-dialog
        title="服务商运输"
        center
        width="1100px"
        :visible.sync="transportations"
      >
        <el-form :model="transports" ref="transports" :rules="transportRules">
          <div style="display: flex">
            <el-form-item
              label="请输入运输数量："
              prop="trafficCount"
              :label-width="formLabelWidth"
              v-if="!isBidding"
            >
              <el-input
                placeholder="请输入运输数量(吨)"
                maxlength="5"
                v-model="transports.trafficCount"
                autocomplete="off"
                :max="data.orderCount"
                @input="receivingFormSignCountss"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="请输入运输单价："
              prop="trafficPrice"
              label-width="190px"
              v-if="!isBidding"
            >
              <el-input
                placeholder="请输入运输单价(元/吨)"
                maxlength="5"
                v-model="transports.trafficPrice"
                autocomplete="off"
                :max="data.orderCount"
              >
              </el-input>
            </el-form-item>
          </div>

          <div style="display: flex; flex-wrap: wrap">
            <div
              class="line goods-list"
              v-for="(goods, index) in transports.trafficDetailList"
              :key="goods.key"
            >
              <el-form-item
                label=""
                :label-width="formLabelWidth"
                :prop="'trafficDetailList.' + index + '.categoryId'"
                :rules="{
                  required: true,
                  message: '请选择商品名',
                  trigger: 'change',
                }"
                v-if="isBidding"
              >
                <el-select
                  v-model="transports.trafficDetailList[index].categoryId"
                  placeholder="请选择商品名"
                  style="width: 150px"
                >
                  <el-option
                    v-for="(item, goodsIndex) in goodsCategoryList"
                    :key="item.goodsType + goodsIndex"
                    :label="item.goodsName"
                    :value="item.categoryId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="运输数量:"
                label-width="100px"
                :prop="'trafficDetailList.' + index + '.number'"
                :rules="{
                  required: true,
                  message: '请输入数量',
                  trigger: 'blur',
                }"
                v-if="isBidding"
              >
                <el-input
                  :placeholder="'请输入数量(吨)'"
                  style="width: 150px"
                  maxlength="20"
                  v-model.trim="transports.trafficDetailList[index].number"
                  autocomplete="off"
                  min="0.1"
                  @input="
                    formatFloat1(
                      transports.trafficDetailList[index],
                      'number',
                      'count',
                      3
                    )
                  "
                ></el-input>
              </el-form-item>
              <el-form-item
                label="运输单价:"
                label-width="100px"
                :prop="'trafficDetailList.' + index + '.goodsPrice'"
                :rules="{
                  required: true,
                  message: '请输入运输单价',
                  trigger: 'blur',
                }"
                v-if="isBidding"
              >
                <el-input
                  :placeholder="'请输入运输单价(吨)'"
                  style="width: 150px"
                  maxlength="20"
                  v-model.trim="transports.trafficDetailList[index].goodsPrice"
                  autocomplete="off"
                  min="0.1"
                  @input="
                    formatFloat1(
                      transports.trafficDetailList[index],
                      'goodsPrice',
                      'price',
                      3
                    )
                  "
                ></el-input>
                <el-button
                  v-if="goods.key == 0"
                  style="margin-left: 10px"
                  @click="addGoodes(3)"
                  >增加</el-button
                >
                <el-button
                  v-if="goods.key != 0"
                  style="margin-left: 10px"
                  @click="delGoods(goods, 3)"
                  >删除</el-button
                >
              </el-form-item>
            </div>
            <el-form-item
              label="请选择商品名称："
              prop="goodsName"
              :label-width="formLabelWidth"
              v-if="!isBidding"
            >
              <el-cascader
                v-model="transports.goodsName"
                :options="nameList"
                :props="{ value: 'id', label: 'dictName' }"
                placeholder="请选择商品名称"
                @change="goodsChange"
              ></el-cascader>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item
              label="请选择付款类型："
              prop="payWay"
              :label-width="formLabelWidth"
            >
              <el-select
                v-model="transports.payWay"
                placeholder="请选择付款类型"
              >
                <el-option label="先货后款" value="1"></el-option>
                <el-option label="先款后货" value="2"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item
              label="请输入联系人："
              prop="contacts"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="transports.contacts"
                placeholder="请输入联系人"
                type="text"
                onkeyup="value=value.replace(/[\d]/g,'') "
                maxlength="20"
                minlength="2"
                onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
                autocomplete="off"
                @blur="transportContactss"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="请输入联系方式："
              prop="contactInfo"
              label-width="190px"
            >
              <el-input
                v-model="transports.contactInfo"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                placeholder="请输入联系方式"
                autocomplete="off"
                @blur="transportContactInfos"
              >
              </el-input>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item
              label="请选择起始地："
              prop="startPlace"
              :label-width="formLabelWidth"
            >
              <el-cascader
                v-model="transports.startPlace"
                :options="citys"
                @change="selectChanges('startPlace')"
                placeholder="请输入起始地"
                :props="{
                  value: 'label',
                  label: 'label',
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item
              label="请输入起始地详细地址："
              prop="startAddress"
              label-width="190px"
            >
              <el-input
                v-model="transports.startAddress"
                placeholder="请输入起始地详细地址"
                type="text"
                onkeyup="value=value.replace(/[\d]/g,'') "
                onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
                autocomplete="off"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item
              label="请选择目的地："
              prop="destPlace"
              :label-width="formLabelWidth"
            >
              <!-- <el-input v-model="transports.destPlace" type="text" placeholder="请输入目的地"
								onkeyup="value=value.replace(/[\d]/g,'') "
								onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
								autocomplete="off" @blur="transportDestPlace"></el-input> -->
              <el-cascader
                v-model="transports.destPlace"
                :options="citys"
                @change="selectChanges('destPlace')"
                placeholder="请输入目的地"
                :props="{
                  value: 'label',
                  label: 'label',
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item
              label="请输入目的地详细地址："
              prop="endAddress"
              label-width="190px"
            >
              <el-input
                v-model="transports.endAddress"
                placeholder="请输入目的地详细地址"
                type="text"
                onkeyup="value=value.replace(/[\d]/g,'') "
                onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
                autocomplete="off"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="transportations = false">取 消</el-button>
          <el-button type="primary" @click="carRiages">下一步</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 发起运输 -->
    <div>
      <el-dialog
        title="发起运输"
        center
        :visible.sync="transportation"
        :width="isBidding ? '700px' : '535px'"
        @close="closeChang"
      >
        <el-form :model="transport" ref="transport" :rules="transportRule">
          <div>
            <el-form-item
              label="运输数量："
              prop="trafficCount"
              :label-width="formLabelWidth"
              v-if="!isBidding"
            >
              <el-input
                placeholder="请输入运输数量(吨)"
                v-model="transport.trafficCount"
                autocomplete="off"
                :max="data.orderCount"
                onkeyup="value=value.replace(/[^\d\.]/g,'')"
                @input="receivingFormSignCount('trafficCount')"
              >
              </el-input>
            </el-form-item>
            <div
              class="line transport-goods-list"
              v-for="(goods, index) in transport.trafficDetailList"
              :key="goods.key"
            >
              <el-form-item
                label="商品信息"
                :label-width="formLabelWidth"
                :prop="'trafficDetailList.' + index + '.categoryId'"
                :rules="{
                  required: true,
                  message: '请选择商品名',
                  trigger: 'change',
                }"
                v-if="isBidding"
              >
                <el-select
                  v-model="transport.trafficDetailList[index].categoryId"
                  placeholder="请选择商品名"
                  style="width: 150px"
                >
                  <el-option
                    v-for="(item, goodsIndex) in goodsCategoryList"
                    :key="item.goodsType + goodsIndex"
                    :label="item.goodsName"
                    :value="item.categoryId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="运输数量:"
                label-width="100px"
                :prop="'trafficDetailList.' + index + '.number'"
                :rules="{
                  required: true,
                  message: '请输入数量',
                  trigger: 'blur',
                }"
                v-if="isBidding"
              >
                <el-input
                  :placeholder="'请输入数量(吨)'"
                  style="width: 150px"
                  maxlength="20"
                  v-model.trim="transport.trafficDetailList[index].number"
                  autocomplete="off"
                  min="0.1"
                  @input="
                    formatFloat1(
                      transport.trafficDetailList[index],
                      'number',
                      'count',
                      2
                    )
                  "
                ></el-input>
                <el-button
                  v-if="goods.key == 0"
                  style="margin-left: 10px"
                  @click="addGoodes(1)"
                  >增加</el-button
                >
                <el-button
                  v-if="goods.key != 0"
                  style="margin-left: 10px"
                  @click="delGoods(goods, 1)"
                  >删除</el-button
                >
              </el-form-item>
            </div>
            <el-form-item
              label="运输方式："
              prop="trafficWay"
              :label-width="formLabelWidth"
            >
              <el-select v-model="transport.trafficWay" placeholder="请选择">
                <el-option label="公路运输" value="1"></el-option>
                <el-option label="铁路运输" value="2"></el-option>
                <el-option label="公铁联运" value="3"></el-option>
                <!-- <el-option label="海运" value="4"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item
              label="请选择起始地："
              prop="startPlace"
              :label-width="formLabelWidth"
            >
              <el-cascader
                v-model="transport.startPlace"
                :options="citys"
                @change="selectChange('startPlace')"
                placeholder="请选择起始地"
                :props="{
                  value: 'label',
                  label: 'label',
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item
              label="请输入目的地："
              prop="destPlace"
              :label-width="formLabelWidth"
            >
              <el-cascader
                v-model="transport.destPlace"
                :options="citys"
                @change="selectChange('destPlace')"
                placeholder="请输入目的地"
                :props="{
                  value: 'label',
                  label: 'label',
                }"
              ></el-cascader>
            </el-form-item>
          </div>
          <div>
            <el-form-item
              label="请输入联系人："
              prop="contacts"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="transport.contacts"
                placeholder="请输入联系人"
                type="text"
                onkeyup="value=value.replace(/[\d]/g,'') "
                maxlength="20"
                minlength="2"
                onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
                autocomplete="off"
                @blur="transportContacts"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="联系方式："
              prop="contactInfo"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="transport.contactInfo"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                placeholder="请输入联系方式"
                autocomplete="off"
                @blur="transportContactInfo"
                maxlength="11"
              >
              </el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item
              label="运输凭证："
              prop="fileIds"
              :label-width="formLabelWidth"
            >
              <div style="display: flex">
                <el-upload
                  class="upload-demo"
                  action="#"
                  :http-request="addinspectUrlUrls"
                  accept=".bmp,.jpg,.png,.jpeg,.pdf"
                  :limit="1000"
                  :file-list="transportfileList"
                  :on-exceed="handleExceedel"
                  :on-change="handleChangerel"
                  multiple
                  :show-file-list="false"
                >
                  <el-button type="primary">点击上传</el-button>
                </el-upload>
                <el-button
                  class="img-box"
                  style="margin-left: 20px"
                  @click="inspectprevie"
                  v-if="ransportviews.length > 0"
                  >点击查看所有图片<i
                    @click.stop="delImg"
                    class="del-img el-icon-delete-solid"
                  ></i
                ></el-button>
              </div>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="transportation = false">取 消</el-button>
          <el-button type="primary" @click="carRiage">提交</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 预览铁路运输发票 -->
    <div>
      <el-dialog title="预览" width="30%" :visible.sync="receivingVisi">
        <div class="block">
          <el-carousel trigger="click" :autoplay="false" min-height="300px">
            <el-carousel-item
              v-for="(item, index) in ransportviews"
              :key="index"
              class="image_item"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <el-button
                @click="examinecontract(item)"
                v-if="
                  (item && item.slice(-4) == '.pdf') ||
                  (item && item.slice(-4) == '.PDF')
                "
              >
                点击查看PDF文件<br /><br /><br />{{ item }}</el-button
              >
              <el-image
                v-else
                :src="baseUrl + 'third/third/ossGetFile/' + item"
                :preview-src-list="[baseUrl + 'third/third/ossGetFile/' + item]"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <el-button type="primary" @click="receivingVisi = false"
            >确定</el-button
          >
        </div>
      </el-dialog>
    </div>
    <!-- 支付 -->
    <el-dialog
      :title="dialogTitle"
      :width="isBidding ? '50%' : '30%'"
      :visible.sync="dialogofflineForm"
      @close="CloseChange"
    >
      <el-form :model="offlineForm" ref="offlineForm" :rules="offlineFormRule">
        <el-form-item
          label="请选择支付类型:"
          :label-width="formLabelWidth"
          v-if="isBidding"
          prop="payBrand"
          :rules="{
            required: true,
            message: '请选择支付类型',
            trigger: 'change',
          }"
        >
          <el-radio-group
            v-model="offlineForm.payBrand"
            @input="offlineFormPayTypeChange"
          >
            <el-radio :label="2">履约保证金支付</el-radio>
            <el-radio :label="1">预付款支付</el-radio>
            <el-radio :label="0">按数量支付</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="总数量:"
          :label-width="formLabelWidth"
          v-if="!isLogistics && !isBidding"
        >
          {{ data.orderCount }}吨
        </el-form-item>
        <el-form-item
          label="支付数量:"
          prop="payCount"
          :label-width="formLabelWidth"
          v-if="!isBidding"
        >
          <el-input
            :placeholder="'请输入数量(吨)'"
            maxlength="20"
            v-model.trim="offlineForm.payCount"
            autocomplete="off"
            @input="formatFloat('payCount')"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="中标价格:"
          prop="payPrice"
          maxlength="5"
          :label-width="formLabelWidth"
          v-if="isLogistics"
        >
          <el-input
            v-model.trim="offlineForm.payPrice"
            autocomplete="off"
            onkeyup="value=value.replace(/[^\d]/g,'')"
            maxlength="5"
            placeholder="请输入单价(元/吨)"
            :disabled="isoffline"
            @blur="offlineFormPayPrice"
            @input="formatFloat('payPrice')"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="单价:"
          prop="payPrice"
          maxlength="5"
          :label-width="formLabelWidth"
          v-if="!isLogistics && !isBidding"
        >
          <!-- <el-input v-model.trim="offlineForm.payPrice" autocomplete="off" v-if="annex"
						onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="5" placeholder="请输入单价(元/吨)"
						 @blur="offlineFormPayPrice" @input="formatFloat('payPrice')"></el-input>
					<el-input v-model.trim="offlineForm.payPrice" autocomplete="off" v-else
						onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="5" placeholder="请输入单价(元/吨)"
						:disabled="isoffline" @blur="offlineFormPayPrice" @input="formatFloat('payPrice')"></el-input>
             -->
          <el-input
            v-model.trim="offlineForm.payPrice"
            autocomplete="off"
            v-if="annex"
            placeholder="请输入单价(元/吨)"
            :maxlength="$route.query.ifInvite == 1 ? 50 : 5"
            @blur="offlineFormPayPrice"
            @input="formatFloat('payPrice')"
          ></el-input>
          <el-input
            v-model.trim="offlineForm.payPrice"
            autocomplete="off"
            v-else
            placeholder="请输入单价(元/吨)"
            :maxlength="$route.query.ifInvite == 1 ? 50 : 5"
            :disabled="isoffline"
            @blur="offlineFormPayPrice"
            @input="formatFloat('payPrice')"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="请输入支付金额:"
          :label-width="formLabelWidth"
          prop="payAmount"
          :rules="{
            required: true,
            message: '请输入支付金额',
            trigger: 'blur',
          }"
          v-if="offlineForm.payBrand !== 0 && isBidding"
        >
          <el-input
            :placeholder="'请输入支付金额(元)'"
            maxlength="20"
            v-model.trim="offlineForm.payAmount"
            autocomplete="off"
            min="0.1"
            @input="formatFloat1(offlineForm, 'payAmount', 'price', 1)"
            ><template slot="append">元</template></el-input
          >
        </el-form-item>
        <div
          class="line goods-list"
          v-for="(goods, index) in offlineForm.priceDetailList"
          :key="goods.key"
        >
          <el-form-item
            label=""
            label-width="100px"
            :prop="'priceDetailList.' + index + '.categoryId'"
            :rules="{
              required: true,
              message: '请选择商品名',
              trigger: 'change',
            }"
            v-if="offlineForm.payBrand === 0 && isBidding"
          >
            <el-select
              v-model="offlineForm.priceDetailList[index].categoryId"
              placeholder="请选择商品名"
              @change="(params) => goodsNameChange(params, index, 1)"
              style="width: 150px"
            >
              <el-option
                v-for="(item, goodsIndex) in goodsCategoryList"
                :key="item.goodsType + goodsIndex"
                :label="item.goodsName"
                :value="item.categoryId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="支付数量:"
            label-width="100px"
            :prop="'priceDetailList.' + index + '.number'"
            :rules="{
              required: true,
              message: '请输入数量',
              trigger: 'blur',
            }"
            v-if="offlineForm.payBrand === 0 && isBidding"
          >
            <el-input
              :placeholder="'请输入数量(吨)'"
              style="width: 150px"
              maxlength="20"
              v-model.trim="offlineForm.priceDetailList[index].number"
              autocomplete="off"
              min="0.1"
              @input="
                formatFloat1(
                  offlineForm.priceDetailList[index],
                  'number',
                  'count',
                  1
                )
              "
            ></el-input>
          </el-form-item>
          <el-form-item
            label="支付单价:"
            label-width="100px"
            :prop="'priceDetailList.' + index + '.goodsPrice'"
            :rules="{
              required: true,
              message: '请输入支付单价',
              trigger: 'blur',
            }"
            v-if="offlineForm.payBrand === 0 && isBidding"
          >
            <el-input
              :placeholder="'请输入支付单价(吨)'"
              style="width: 150px"
              maxlength="20"
              v-model.trim="offlineForm.priceDetailList[index].goodsPrice"
              autocomplete="off"
              :disabled="
                goodsPriceIsNegotiated(
                  offlineForm.priceDetailList[index].categoryId
                )
              "
              min="0.1"
              @input="
                formatFloat1(
                  offlineForm.priceDetailList[index],
                  'goodsPrice',
                  'price',
                  1
                )
              "
              @blur="
                blurFormatFloat1(
                  offlineForm.priceDetailList[index],
                  'goodsPrice',
                  'price'
                )
              "
            ></el-input>
            <el-button
              v-if="goods.key == 0"
              style="margin-left: 10px"
              @click="addGoodes(2)"
              >增加</el-button
            >
            <el-button
              v-if="goods.key != 0"
              style="margin-left: 10px"
              @click="delGoods(goods, 2)"
              >删除</el-button
            >
          </el-form-item>
        </div>
        <el-form-item
          label="支付方式:"
          prop="bankType"
          :label-width="formLabelWidth"
          v-if="dialogTitle == '线上支付'"
        >
          <el-radio
            v-for="(item, index) in balances"
            :key="index"
            v-model="offlineForm.bankType"
            :label="item.bankType"
            :disabled="item.ifAsh"
          >
            <span v-if="item.bankType == 0"
              >中信账户余额(￥{{ item.balance }})</span
            >
            <span v-if="item.bankType == 1"
              >中金账户余额(￥{{ item.balance }})</span
            >
          </el-radio>
        </el-form-item>
        <el-form-item
          label="收款账户信息:"
          :label-width="formLabelWidth"
          v-if="dialogTitle == '线下支付'"
        >
          <!-- <el-input v-model="offlineForm.payCount" autocomplete="off"></el-input> -->
          <div style="display: flex" v-if="enterprise">
            <div class="copyinput">收款账号:{{ enterprise.accountNumber }}</div>
            <div
              style="margin-left: 10px; color: #0479fc"
              @click="copyFn(enterprise.accountNumber)"
            >
              复制
            </div>
          </div>
          <div style="display: flex" v-if="enterprise">
            <div>收款户名:</div>
            <div>{{ enterprise.accountName }}</div>
          </div>
          <div style="display: flex" v-if="enterprise">
            <div>收款银行:</div>
            <div>{{ enterprise.accountBank }}</div>
          </div>
        </el-form-item>
      </el-form>
      <div style="font-size: 20px; margin-left: 40px">
        需支付金额 ：<span style="color: red">￥</span>
        <span style="color: red; font-size: 30px">{{ ficenum }}</span>
        元
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogofflineForm = false">取 消</el-button>
        <el-button type="primary" @click="flineFormClicks(headertype)"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 预览 -->
    <el-dialog title="预览" :visible.sync="preview" width="30%" center>
      <div class="previewIm">
        <!-- <img :src="previewImage" alt="" /> -->
        <el-image
          v-if="previewImage"
          :src="previewImage"
          :preview-src-list="[previewImage]"
        >
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="preview = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 签章列表 -->
    <el-dialog
      title="在线签章"
      :visible.sync="dialogopreview"
      :close-on-click-modal="false"
      width="1100px"
      class="sign"
      :show-close="false"
      center
    >
      <div class="iframe">
        <onlineSignature
          v-if="dialogopreview"
          :Softsign="Softsign"
          :signaturePwd="signaturePwd"
          :signatureName="signatureName"
          :documentId="documentId"
          @closeSign="closeDialogopreview"
        >
        </onlineSignature>
      </div>
    </el-dialog>

    <el-dialog title="附件预览" :visible.sync="isaccounts" center>
      <div class="">
        <div
          v-for="(item, index) in annexUrls"
          :key="index"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
          "
        >
          <el-button
            @click="examinecontract(item.storageName)"
            v-if="
              item.storageName.slice(-4) == '.pdf' ||
              item.storageName.slice(-4) == '.PDF'
            "
          >
            点击查看PDF文件<br /><br /><br />{{ item.originalName }}</el-button
          >
          <el-image
            v-else
            :src="baseUrl + 'third/third/ossGetFile/' + item.storageName"
            style="height: 100px"
            :preview-src-list="[
              baseUrl + 'third/third/ossGetFile/' + item.storageName,
            ]"
          ></el-image>
        </div>
      </div>
    </el-dialog>
    <!-- 签收查看票据 -->
    <el-dialog title="预览" :visible.sync="dialogopreviews" width="40%" center>
      <div class="dialogopreviews_item">
        <div class="item_content">
          <div>煤炭检验单</div>
          <div v-if="thepreview.inspectUrl == ''">未上传</div>
          <div v-else>
            <el-image
              v-if="thepreview.inspectUrl"
              style="width: 200px"
              :src="baseUrl + 'third/third/ossGetFile/' + thepreview.inspectUrl"
              :preview-src-list="[
                baseUrl + 'third/third/ossGetFile/' + thepreview.inspectUrl,
              ]"
            >
            </el-image>
          </div>
        </div>
        <div class="item_content">
          <div>电厂收货单</div>
          <div
            v-if="thepreview.invoiceUrls && thepreview.invoiceUrls.length == 0"
          >
            未上传
          </div>
          <div v-else>
            <div style="width: 200px; height: 200px">
              <el-carousel trigger="click" :autoplay="false" height="150px">
                <el-carousel-item
                  v-for="(item, index) in thepreview.invoiceUrls"
                  :key="index"
                  class="image_item"
                >
                  <el-image
                    :src="
                      baseUrl + 'third/third/ossGetFile/' + item.storageName
                    "
                    :preview-src-list="[
                      baseUrl + 'third/third/ossGetFile/' + item.storageName,
                    ]"
                  ></el-image>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <div class="item_content">
          <div>运输凭证</div>
          <div
            v-if="thepreview.receiptUrls && thepreview.receiptUrls.length == 0"
          >
            未上传
          </div>
          <div v-else>
            <div style="width: 200px; height: 200px">
              <el-carousel trigger="click" :autoplay="false" height="150px">
                <el-carousel-item
                  v-for="(item, index) in thepreview.receiptUrls"
                  :key="index"
                  class="image_item"
                >
                  <el-image
                    :src="
                      baseUrl + 'third/third/ossGetFile/' + item.storageName
                    "
                    :preview-src-list="[
                      baseUrl + 'third/third/ossGetFile/' + item.storageName,
                    ]"
                  ></el-image>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <div>
      <el-dialog title="预览" width="30%" :visible.sync="receivingVisibles">
        <div class="block">
          <el-carousel trigger="click" :autoplay="false" height="300px">
            <el-carousel-item
              v-for="(item, index) in sunbmitpreview"
              :key="index"
              class="image_item"
            >
              <el-image
                :src="baseUrl + 'third/third/ossGetFile/' + item"
                :preview-src-list="[baseUrl + 'third/third/ossGetFile/' + item]"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
      </el-dialog>
    </div>
    <!-- 运输凭证预览 -->
    <div>
      <el-dialog title="预览" width="30%" :visible.sync="receivingVisibless">
        <div class="block">
          <el-carousel trigger="click" :autoplay="false" height="300px">
            <el-carousel-item
              v-for="(item, index) in sunbmitpreviews"
              :key="index"
              class="image_item"
            >
              <el-image
                :src="baseUrl + 'third/third/ossGetFile/' + item"
                :preview-src-list="[baseUrl + 'third/third/ossGetFile/' + item]"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
      </el-dialog>
    </div>
    <!-- 结算单据查看 -->
    <div>
      <el-dialog title="预览" width="30%" :visible.sync="istrafficDocUrls">
        <div class="block">
          <el-carousel trigger="click" :autoplay="false" height="300px">
            <el-carousel-item
              v-for="(item, index) in trafficDocUrlslist"
              :key="index"
              class="image_item"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <el-button
                @click="examinecontract(item.storageName)"
                v-if="
                  item.storageName.slice(-4) == '.pdf' ||
                  item.storageName.slice(-4) == '.PDF'
                "
              >
                点击查看PDF文件<br /><br /><br />{{
                  item.storageName
                }}</el-button
              >
              <el-image
                v-else
                :src="baseUrl + 'third/third/ossGetFile/' + item.storageName"
                :preview-src-list="[
                  baseUrl + 'third/third/ossGetFile/' + item.storageName,
                ]"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
      </el-dialog>
    </div>
    <!-- 合同预览 -->
    <el-dialog title="合同预览" :visible.sync="isaccount" center>
      <div class="">
        <div
          v-for="(item, index) in contimgs"
          :key="index"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
          "
        >
          <el-button
            @click="examinecontract(item.storageName)"
            v-if="
              item.storageName.slice(-4) == '.pdf' ||
              item.storageName.slice(-4) == '.PDF'
            "
          >
            点击查看PDF文件<br /><br /><br />{{ item.originalName }}
          </el-button>
          <el-button
            @click="examinecontract(item.storageName)"
            v-if="item.storageName.slice(-5) == '.docx'"
          >
            点击查看docx文件<br /><br /><br />{{ item.originalName }}
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="发起结算"
      :visible.sync="receivingVisiblebel"
      width="40%"
      center
    >
      <div class="danju">
        <div class="danju_item">
          <span style="color: red">*</span> 结算单据：
        </div>
        <el-upload
          class="upload-demo"
          action="#"
          accept=".bmp,.jpg,.png,.jpeg"
          :http-request="addsettleUrlres"
          :show-file-list="false"
          multiple
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
        <div style="margin-left: 30px">
          <el-image
            v-if="settleUrls"
            style="width: 100px"
            :src="fileBaseUrl + settleUrls"
            :preview-src-list="[fileBaseUrl + settleUrls]"
          >
          </el-image>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="receivingVisiblebel = false">取 消</el-button>
        <el-button type="primary" @click="carRiageres">提交</el-button>
      </div>
    </el-dialog>
    <signDownloadDrive ref="signDownloadDrive"></signDownloadDrive>
    <InputPasswordDialog
      :isShow="passWordIsShows"
      ref="InputPasswordDialog"
      title="请输入交易密码"
      @close="closeDialog('passWordIsShow')"
      @submit="submit"
    ></InputPasswordDialog>
    <!-- 企业名称获取 -->
    <el-dialog title="登录" :visible.sync="dialogVisibless" width="500px">
      <div>
        <el-form
          :model="numberValidateForms"
          :refs="numberValidateFormrules"
          ref="numberValidateForms"
          label-width="150px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="企业名称"
            prop="name"
            :rules="[{ required: true, message: '企业名称不能为空' }]"
          >
            <el-input
              disabled
              v-model.number="numberValidateForms.name"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="统一信用代码"
            prop="card_id"
            :rules="[{ required: true, message: '统一信用代码不能为空' }]"
          >
            <el-input
              disabled
              v-model.number="numberValidateForms.card_id"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <div
            style="
              text-align: right;
              width: 80%;
              color: blue;
              cursor: pointer;
              margin: 20px 0;
            "
            @click="(dialogVisibless = false), (dialogVisibles = true)"
          >
            使用账号及密码获取
          </div>
          <el-form-item>
            <el-button type="primary" @click="submitForm('numberValidateForms')"
              >提交</el-button
            >
            <el-button @click="dialogVisibless = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 账号密码获取 -->
    <el-dialog
      title="登录"
      :visible.sync="dialogVisibles"
      :before-close="numberValidateFormclose"
      width="400px"
    >
      <div>
        <el-form
          :model="numberValidateForm"
          :rules="numberValidateFormrules"
          ref="numberValidateForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="账号" prop="account">
            <el-input
              v-model="numberValidateForm.account"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="sign_pwd">
            <el-input
              type="password"
              v-model="numberValidateForm.sign_pwd"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <div
            style="
              text-align: right;
              width: 88%;
              color: blue;
              cursor: pointer;
              margin: 20px 0;
            "
            @click="(dialogVisibless = true), numberValidateFormclose()"
          >
            使用其他方式获取
          </div>
          <el-form-item>
            <el-button type="primary" @click="submitForm('numberValidateForm')"
              >提交</el-button
            >
            <el-button @click="dialogVisibles = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      title="预览"
      width="30%"
      append-to-body
      :visible.sync="receivingFileView1"
    >
      <div class="block">
        <el-carousel trigger="click" :autoplay="false" height="300px">
          <el-carousel-item
            v-for="(item, index) in receivingFiles"
            :key="index"
            class="image_item"
            style="display: flex; align-items: center; justify-content: center"
          >
            <el-button
              @click="examinecontract(item.storageName)"
              v-if="
                item.storageName.slice(-4) == '.pdf' ||
                item.storageName.slice(-4) == '.PDF'
              "
            >
              点击查看PDF文件<br /><br /><br />{{ item.storageName }}</el-button
            >
            <el-image
              v-else
              :src="baseUrl + 'third/third/ossGetFile/' + item.storageName"
              :preview-src-list="[
                baseUrl + 'third/third/ossGetFile/' + item.storageName,
              ]"
            ></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="dialogVisibleClonse"
    >
      <span>请选择您的签章方式</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="mildSignature">软签获取</el-button>
        <el-button type="primary" @click="Toobtainan">ukey获取</el-button>
      </span>
    </el-dialog>
    <iframe
      style="display: none"
      width="960px"
      height="100%"
      frameborder="0"
      :src="serverUrl + '/iCloudFrontLoader/pc#/'"
      id="topFrame"
      name="topFrame"
    ></iframe>
  </div>
</template>

<script>
import * as ESASignClient from "@/utils/signConfig/ESASignClient.js";
import particulars from "@/layout/particulars.vue";
import onlineSignature from "./components/onlineSignature";
const { baseUrl } = require("@/config");
import { getAccountBalanceByParam } from "@/api/Accountopening";
import url from "@/api/url/hy_url";
import {
  ossUploadFiletw,
  ossUploadFile,
  saveContractUrl,
  invoicepage,
  paymentpage,
  trafficpage,
  signpage,
  addtrafficpage,
  addinvoice,
  detailByOrder,
  confirm,
  addpayment,
  payTradeOrder,
  addconfirm,
  addsign,
  confirmReceive,
  finish,
  updateVoucher,
  listTemplate,
  confirmInvoice,
  confirmDeliver,
  confirmSettle,
  getNewestInvoice,
  placeTransportOrderFromTrade,
  ossUploadFiletwo,
  getOnlineContractInfo,
  updateOnlineContractInfo,
  listAdjustPrice,
  addAdjustPrice,
  orderDetail,
  getOrderPrice,
  dealAdjustPrice,
  getPrepaymentBalance,
  getSignDetail,
  exportSign,
  returnPrepayment,
  cancelSettle,
} from "@/api/public";
import { getCompanyAuthDetail, getContractOSSFile } from "@/api/userCenters.js";
import signBaseUrl from "@/config/signConfig";
import InputPasswordDialog from "./components/passwordInput.vue";
import md5 from "js-md5";
import citys from "@/assets/json/citys";
import { serviceGoodCollection } from "@/api/home";
import { reqCompanyAuthDetail, reqDictType } from "@/api/hy";
import { getLoginUrl, getContractState } from "@/api/userCenters.js";
import * as types from "@/store/action-types";
import Vue from "vue";
import signDownloadDrive from "@/components/signDownloadDrive.vue";
let userId = Vue.ls.get(types.userId);
import { formatDate } from "../../utils/date";
export default {
  components: {
    particulars,
    onlineSignature,
    signDownloadDrive,
    InputPasswordDialog,
  },
  data() {
    return {
      annex: false,
      balances: [],
      firstDisabled: false,
      threeDisabled: false,
      thirdDisabled: false,
      fourthDisabled: false,
      fiveDisabled: false,
      secondDisabled: false,
      onlineInfo: {
        contractNo: "",
      },
      tableKey:0,
      goodsInfoList: {
        coalName: "",
        price: "",
        quantity: "",
        totalAmount: "",
        quota: "",
      },
      goodsInfoListrules: {
        coalName: [
          {
            required: true,
            message: "请输入产品名称",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "请输入产品单价",
            trigger: "blur",
          },
        ],
        quantity: [
          {
            required: true,
            message: "请输入产品数量",
            trigger: "blur",
          },
        ],
        totalAmount: [
          {
            required: true,
            message: "请输入产品总价",
            trigger: "blur",
          },
        ],
        quota: [
          {
            required: true,
            message: "请输入产品指标",
            trigger: "blur",
          },
        ],
      },
      onlineInforules: {
        contractNo: [
          {
            required: true,
            message: "请输入合同编号",
            trigger: "blur",
          },
        ],
        supplier: [
          {
            required: true,
            message: "请输入供方",
            trigger: "blur",
          },
        ],
        demander: [
          {
            required: true,
            message: "请输入需方",
            trigger: "blur",
          },
        ],
        supplierContact: [
          {
            required: true,
            message: "请输入联系人",
            trigger: "blur",
          },
        ],
        demanderContact: [
          {
            required: true,
            message: "请输入联系人",
            trigger: "blur",
          },
        ],
        demanderSigningDate: [
          {
            required: true,
            message: "请选择签订日期",
            trigger: "blur",
          },
        ],
        transportType: [
          {
            required: true,
            message: "请选择运输方式",
            trigger: "change",
          },
        ],
        effectiveStartDate: [
          {
            required: true,
            message: "请选择有效开始时间",
            trigger: "blur",
          },
        ],
        effectiveEndDate: [
          {
            required: true,
            message: "请选择有效结束时间",
            trigger: "blur",
          },
        ],
        deliveryType: [
          {
            required: true,
            message: "请选择交货方式",
            trigger: "change",
          },
        ],
        signingPlace: [
          {
            required: true,
            message: "请输入签订地",
            trigger: "blur",
          },
        ],
        payType: [
          {
            required: true,
            message: "请选择付款方式",
            trigger: "change",
          },
        ],
        number: [
          {
            required: true,
            message: "请输入数量",
            trigger: "blur",
          },
        ],
        distributionType: [
          {
            required: true,
            message: "请选择配送方式",
            trigger: "change",
          },
        ],
        totalAmount: [
          {
            required: true,
            message: "请输入总额",
            trigger: "blur",
          },
        ],
        deliveryAddress: [
          {
            required: true,
            message: "请输入交换地",
            trigger: "blur",
          },
        ],
      },
      onlineInForm: {},
      dialogTableVisibles: false,
      signatureName: "",
      signaturePwd: "",
      Softsign: false,
      serverUrl: "",
      dialogVisibless: false,
      numberValidateForm: {
        account: "",
        sign_pwd: "",
      },
      numberValidateForms: {
        name: "",
        card_id: "",
      },
      dialogVisibles: false,
      dialogVisible: false,
      passWordIsShows: false,
      headertype: 0,
      dialogTitle: "",
      citys: citys,
      documentId: "",
      formData: {},
      nameList: [],
      transportations: false,
      ficenum: 0,
      istrafficDocUrls: false,
      trafficDocUrlslist: [],
      receivingVisi: false,
      ransportviews: [],
      transportfileList: [],
      deliverCount: null,
      receivingVisiblebel: false,
      signId: null,
      isshipments: false,
      receivingVisibles: false,
      inspectfileLists: [],
      inspectfileList: [],
      isoffline: false,
      isaccount: false,
      sunbmitpreview: [],
      contimgs: [],
      imglist: [],
      fileList: [],
      fileList1: [],
      sunbmitpreviews: [],
      data: {},
      fileLists: [],
      fileBaseUrl: baseUrl + url.getFileUrl,
      dialogopreviews: false,
      thepreview: {
        inspectUrl: "",
        invoiceUrl: "",
        receiptUrl: "",
        signCount: "",
      },
      formatter: {
        total(row) {
          return row.payAmount * row.payCount;
        },
        trafficWay(row) {
          let _data = {
            0: "未知",
            1: "公路",
            2: "铁路",
            3: "公铁联运",
            4: "海运",
          };
          return _data[row.trafficWay];
        },
      },
      radio: "",
      gridDatas: [
        {
          contractNum: "印章名称",
          contractName: 1,
          contracCode: "5632",
          contracType: "类型",
          contracTypename: "印章类型名称",
          createTime: "2021-11-23 20:00",
        },
      ],
      receivingVisibless: false,
      dialogopreview: false,
      // 签收货物
      receivingVisible: false,
      receivingForm: {
        signCount: "", //签收数量
        inspectUrl: [], //煤炭检验单访问地址
        invoiceUrl: [], //结算单据
        //receiptUrls: [], //电厂收货单访问地址
        settleWay: 1,
        qualityDocumentList: [],
        settleDocumentList: [],
        settleDetailList: [
          {
            categoryId: "",
            number: "",
            goodsPrice: "",
            key: 0,
          },
        ],
      },
      numberValidateFormrules: {
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        sign_pwd: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      receivingFormRule: {
        signCount: [
          {
            required: true,
            message: "请输入结算数量",
            trigger: "blur",
          },
        ],
        invoiceUrl: [
          {
            required: true,
            message: "请上传结算单据",
            trigger: "blur",
          },
        ],
      },
      //发布方true/参与方false
      isPublisher: true,
      // 确认合同上没上传
      contrac: {},
      //预览
      baseUrl: baseUrl,
      preview: false,
      previewImage: "",
      // 发起运输
      transportation: false,
      transport: {
        trafficCount: "",
        startPlace: "",
        trafficWay: "",
        destPlace: "",
        contacts: "",
        contactInfo: "",
        fileIds: [], //运输单据
        trafficDetailList: [
          {
            categoryId: "",
            number: "",
            goodsPrice: "",
            key: 0,
          },
        ],
      },
      transports: {
        trafficCount: "", //运输数量
        trafficPrice: "", //运输单价
        goodsName: "", //商品名称
        payWay: "", //付款类型
        contacts: "", //联系人
        contactInfo: "", //联系方式
        startPlace: "", //起始地
        destPlace: "", //目的地
        trafficDetailList: [
          {
            categoryId: "",
            number: "",
            goodsPrice: "",
            key: 0,
          },
        ],
      },
      // 发起开票form
      formLabelWidth: "150px",
      dialogFormVisible: false,
      invoiceUrlVis: false,
      form: {
        invoiceAmount: "",
        invoiceCount: "",
        invoiceUrl: "", //开票文件地址
        settleUrl: "", //结算文件地址
        invoiceUrlList: [],
        invoiceDetailList: [
          {
            categoryId: "",
            number: 0,
            goodsPrice: 0,
            key: 0,
          },
        ],
      },
      formRules: {
        invoiceAmount: [
          {
            required: true,
            message: "请输入开票金额",
            trigger: "blur",
          },
        ],
        invoiceCount: [
          {
            required: true,
            message: "请输入开票数量",
            trigger: "blur",
          },
        ],
        invoiceUrl: [
          {
            required: true,
            message: "请上传发票单据",
            trigger: "blur",
          },
        ], //开票文件地址
        settleUrl: [
          {
            required: true,
            message: "请上传开票信息",
            trigger: "blur",
          },
        ], //结算文件地址
      },
      transportRules: {
        trafficCount: [
          {
            required: true,
            message: "请输入运输数量",
            trigger: "blur",
          },
          {
            pattern: /^[0-9]+(\.[0-9]{1,4})?$/,
            message: "请输入正确运输数量",
            trigger: "blur",
          },
        ],
        trafficPrice: [
          {
            required: true,
            message: "请输入运输单价",
            trigger: "blur",
          },
        ],
        goodsName: [
          {
            required: true,
            message: "请选择商品名称",
            trigger: "change",
          },
        ],
        payWay: [
          {
            required: true,
            message: "请选择付款类型",
            trigger: "change",
          },
        ],
        startPlace: [
          {
            required: true,
            message: "请输入起始地",
            trigger: "blur",
          },
        ],
        destPlace: [
          {
            required: true,
            message: "请输入目的地",
            trigger: "blur",
          },
        ],
        contacts: [
          {
            required: true,
            message: "请输入联系人名称",
            trigger: "blur",
          },
        ],
        contactInfo: [
          {
            required: true,
            message: "请输入联系方式",
            trigger: "blur",
          },
          {
            pattern: /^[1][0-9]{10}$/,
            message: "请输入正确手机号码",
            trigger: "blur",
          },
        ],
      },
      transportRule: {
        trafficCount: [
          {
            required: true,
            message: "请输入运输数量",
            trigger: "blur",
          },
          {
            pattern: /^[0-9]+(\.[0-9]{1,4})?$/,
            message: "请输入正确运输数量",
            trigger: "blur",
          },
        ],
        trafficWay: [
          {
            required: true,
            message: "请选择运输方式",
            trigger: "change",
          },
        ],
        startPlace: [
          {
            required: true,
            message: "请输入起始地",
            trigger: "blur",
          },
        ],
        destPlace: [
          {
            required: true,
            message: "请输入目的地",
            trigger: "blur",
          },
        ],
        contacts: [
          {
            required: true,
            message: "请输入联系人名称",
            trigger: "blur",
          },
        ],
        contactInfo: [
          {
            required: true,
            message: "请输入联系方式",
            trigger: "blur",
          },
          {
            pattern: /^[1][0-9]{10}$/,
            message: "请输入正确手机号码",
            trigger: "blur",
          },
        ],
      },
      // //////////////////
      dialogTableVisible: false,
      //支付列表
      tableDatas: [],
      //线下支付
      dialogofflineForm: false,
      offlineForm: {
        payPrice: "",
        quantity: "", //总数量
        payAmount: "", //支付金额
        payCount: "", //支付数量‘
        bankType: "",
        payBrand: "",
        priceDetailList: [
          {
            categoryId: "",
            number: "",
            goodsPrice: "",
            key: 0,
          },
        ],
      },
      offlineFormRule: {
        bankType: [
          { required: true, message: "请选择支付方式", trigger: "change" },
        ],
        payCount: [
          {
            required: true,
            message: "请输入支付数量",
            trigger: "blur",
          },
        ],
        payPrice: [
          {
            required: true,
            message: "请输入单价",
            trigger: "blur",
          },
        ],
      },
      // 企业详情信息(银行信息)
      enterprise: {},
      //
      gridData: [],
      activeName: "first",
      //开票列表
      tableData: [],
      // 运输列表
      tralist: [],
      //签收列表
      signlist: [],
      paymentId: 0,
      sellerId: null,
      num: [],
      orderSurplusCount: 0,
      settleUrls: "",
      deliveryMode: null,
      fiel: [],
      productlist: false,
      formInline: {
        userId: userId,
        name: "",
        dictId: 506,
        pageSize: 1000,
        pageNum: 1,
      },
      tableList: [],
      contractId: "",
      contractState: "",
      dictList: [],
      goodsName: "",
      firstDisplay: false,
      threeDisplay: false,
      thirdDisplay: false,
      fourthDisplay: false,
      fiveDisplay: false,
      secondDisplay: false,
      isLogistics: false,
      isaccounts: false,
      annexUrls: [],
      imglist1: [],
      fileList: [],
      fileList1: [],
      fileLists: [],
      annexList: [],
      annexList1: [],
      fileLists1: [],
      adjustPriceDiolog: false,
      MRFileIdList: [],
      tradeGoods: {},
      pageData: {},
      hasPendingAdjustPrice: false,
      addAdjustPriceDiologType: "look",
      ispriceFile: false,
      priceFileList: [],
      priceList: [],
      priceList1: [],
      annexUploadDiolog: false,
      isAnnex1: true,
      annexId: "",
      adjustId: [],
      prepaymentBalance: 0,
      goodsCategoryList: [],
      priceHistoryList: [],
      priceHistoryVisible: false,
      addAdjustPrice: {
        price: "",
        fileUrl: "",
      },
      receivingFiles: [],
      receivingFileView1: false,
      returnSignFileList: [],
      returnSignDiolog: false,
      returnSignfileList: [],
      signDiolog: false,
      signDetailData: {},
      receivingInvoiceUrl: [],
      receivingInvoiceUrl1: [],
      inspectFile: [],
      inspectFile1: [],
      signCountTotal: 0,
      settleAmountTotal: 0,
      receivingFileView: false,
      detailReceivingFileView: false,
      receivingFilesDetail: [],
    };
  },
  filters: {
    initContrac(index) {
      let arr = [
        "未签章",
        "发布方已签章,等待参与方签章",
        "参与方已签章,等待发布方签章",
        "双方都已签章",
      ];

      return arr[index];
    },
    formatType(row) {
      switch (row) {
        case 1:
          return "公路运输";
          break;
        case 2:
          return "铁路运输";
          break;
        case 3:
          return "公铁联运";
          break;
        case 4:
          return "海运";
          break;
        default:
          return " ";
      }
    },
  },
  computed: {
    // 商品价格是否面议
    goodsPriceIsNegotiated() {
      return (val) => {
        return (
          this.goodsCategoryList.find((item) => {
            return item.categoryId == val;
          })?.goodsPrice != 0
        );
      };
    },
    annexShow() {
      let isShow =
        (this.contrac.contractType == 1 && this.contrac.contractStatus == 3) ||
        ((this.contrac.contractType == 2 || this.contrac.contractType == 3) &&
          this.contrac.signatureStatus == 3);
      return isShow;
    },
    tabDisable() {
      // 合同双方确认
      let contrac =
        this.contrac.signatureStatus == null
          ? this.contrac.contractStatus == 3
          : this.contrac.signatureStatus == 3;
      // 附件双方确认
      let annex =
        this.contrac.annexInfo?.contractStatus == -1
          ? true
          : this.contrac.annexInfo?.signatureStatus == null
          ? this.contrac.annexInfo?.contractStatus == 3
          : this.contrac.annexInfo?.signatureStatus == 3;
      return contrac && annex;
    },
    // 合同双方确认
    contracOver() {
      let contrac =
        this.contrac.signatureStatus == null
          ? this.contrac.contractStatus == 3
          : this.contrac.signatureStatus == 3;
      return contrac;
    },
    // 附件双方确认
    annexOver() {
      let annex =
        this.contrac.annexInfo?.contractStatus == -1
          ? true
          : this.contrac.annexInfo?.signatureStatus == null
          ? this.contrac.annexInfo?.contractStatus == 3
          : this.contrac.annexInfo?.signatureStatus == 3;
      return annex;
    },
    // 是否显示签章tab
    signatureTab() {
      // debugger
      // 合同是否需要签章
      let contrac =
        (this.contrac.contractType == 2 || this.contrac.contractType == 3) &&
        this.contrac.contractStatus == 3 &&
        this.contrac.annexInfo?.contractStatus == -1;
      // 附件是否需要签章
      let annex =
        (this.contrac.annexInfo?.contractType == 3 &&
          this.contrac.annexInfo?.contractStatus == 3) ||
        (this.contrac.annexInfoList &&
          this.contrac.annexInfoList.filter((item) => {
            return item.contractType == 3 && item.contractStatus == 3;
          }).length != 0);
      return contrac || annex;
    },
    signatureBtn() {
      // 合同是否签章
      let contrac =
        (this.contrac.contractStatus == 3 &&
          this.contrac.signatureStatus == 0) ||
        (this.contrac.contractStatus == 3 &&
          this.contrac.signatureStatus == 2 &&
          this.isPublisher == "true") ||
        (this.contrac.contractStatus == 3 &&
          this.contrac.signatureStatus == 1 &&
          this.isPublisher == "false");
      // 附件是否签章
      let annex =
        (this.contrac.annexInfo?.contractStatus == 3 &&
          this.contrac.annexInfo?.signatureStatus == 0) ||
        (this.contrac.annexInfo?.contractStatus == 3 &&
          this.contrac.annexInfo?.signatureStatus == 2 &&
          this.isPublisher == "true") ||
        (this.contrac.annexInfo?.contractStatus == 3 &&
          this.contrac.annexInfo?.signatureStatus == 1 &&
          this.isPublisher == "false");
      return contrac || annex;
    },
    // 是否挂牌销售
    isListingSales() {
      return this.tradeGoods.tradeType == 1 && this.tradeGoods.orderType == 1;
    },
    // 是否竞价
    isBidding() {
      return this.tradeGoods.tradeType == 8;
    },
  },
  watch: {
    fileLists() {
      this.isAnnex1 = this.fileLists.length == 0;
    },
  },
  created() {},
  mounted() {
    let ifInvite = this.$route.query.ifInvite;
    let isPublisher = this.$route.query.isPublisher;
    if (ifInvite == 1 && isPublisher) {
      this.annex = true;
    } else {
      this.annex = false;
    }
    this.isLogistics = this.$route.query.isLogistics;
    this.getorder();
    this.isPublisher = this.$route.query.isPublisher;
    this.deliveryMode = this.$route.query.deliveryMode;
    this.getCompany();
    this.getGoodsName();
    // this.getContractState();
    this.getOrderDetail();
    this.getPrepaymentBalance();
  },
  methods: {
    // 取消结算
    receivingCancel(row) {
      cancelSettle({ signId: row.signId }).then((res) => {
        if (res.code == 0) {
          this.$message.success("取消成功");
          this.signgetlist();
          this.getPrepaymentBalance();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    addGoodes(type) {
      if (type == 1) {
        if (this.transport.trafficDetailList.length >= 5) {
          this.$message.error("数量超出限制");
          return;
        }
        this.transport.trafficDetailList.push({
          categoryId: "",
          number: "",
          goodsPrice: "",
          key:
            this.transport.trafficDetailList[
              this.transport.trafficDetailList.length - 1
            ].key + 1,
        });
      }
      if (type == 2) {
        if (this.offlineForm.priceDetailList.length >= 5) {
          this.$message.error("数量超出限制");
          return;
        }
        this.offlineForm.priceDetailList.push({
          categoryId: "",
          number: "",
          goodsPrice: "",
          key:
            this.offlineForm.priceDetailList[
              this.offlineForm.priceDetailList.length - 1
            ].key + 1,
        });
      }
      if (type == 3) {
        if (this.transports.trafficDetailList.length >= 5) {
          this.$message.error("数量超出限制");
          return;
        }
        this.transports.trafficDetailList.push({
          categoryId: "",
          number: "",
          goodsPrice: "",
          key:
            this.transports.trafficDetailList[
              this.transports.trafficDetailList.length - 1
            ].key + 1,
        });
      }
      if (type == 4) {
        if (this.receivingForm.settleDetailList.length >= 5) {
          this.$message.error("数量超出限制");
          return;
        }
        this.receivingForm.settleDetailList.push({
          categoryId: "",
          number: "",
          goodsPrice: "",
          key:
            this.receivingForm.settleDetailList[
              this.receivingForm.settleDetailList.length - 1
            ].key + 1,
        });
      }
      if (type == 5) {
        if (this.form.invoiceDetailList.length >= 5) {
          this.$message.error("数量超出限制");
          return;
        }
        this.form.invoiceDetailList.push({
          categoryId: "",
          number: 0,
          goodsPrice: 0,
          key:
            this.form.invoiceDetailList[this.form.invoiceDetailList.length - 1]
              .key + 1,
        });
      }
    },
    delGoods(goods, type) {
      if (type == 1) {
        this.transport.trafficDetailList =
          this.transport.trafficDetailList.filter(
            (item) => item.key != goods.key
          );
      }
      if (type == 2) {
        this.offlineForm.priceDetailList =
          this.offlineForm.priceDetailList.filter(
            (item) => item.key != goods.key
          );
      }
      if (type == 3) {
        this.transports.trafficDetailList =
          this.transports.trafficDetailList.filter(
            (item) => item.key != goods.key
          );
      }
      if (type == 4) {
        this.receivingForm.settleDetailList =
          this.receivingForm.settleDetailList.filter(
            (item) => item.key != goods.key
          );
      }
      if (type == 5) {
        this.form.invoiceDetailList = this.form.invoiceDetailList.filter(
          (item) => item.key != goods.key
        );
      }
    },
    fileView(files, type) {
      this.receivingFilesDetail = files;
      this.detailReceivingFileView = true;
    },
    goodsNameChange(e, index, type) {
      if (type == 3) {
        this.receivingForm.settleDetailList[index].goodsPrice =
          this.goodsCategoryList.find((item) => item.categoryId == e)
            ?.goodsPrice || 0;
      }
      if (type == 2) {
        this.transports.trafficDetailList[index].goodsPrice =
          this.goodsCategoryList.find((item) => item.categoryId == e)
            ?.goodsPrice || 0;
      }
      if (type == 1) {
        this.offlineForm.priceDetailList[index].goodsPrice =
          this.goodsCategoryList.find((item) => item.categoryId == e)
            ?.goodsPrice || 0;
        this.ficenum = this.offlineForm.priceDetailList
          .reduce((pre, cur) => {
            return pre + (cur.goodsPrice * (cur.number * 10)) / 10;
          }, 0)
          .toString();
        if (this.ficenum.toString().indexOf(".") != -1) {
          this.ficenum = this.ficenum
            .toString()
            .substring(0, this.ficenum.toString().indexOf(".") + 5);
        } else {
          this.ficenum = this.ficenum;
        }
      }
    },
    offlineFormPayTypeChange(e) {
      if (e == 1 || e == 2) {
        this.ficenum = this.offlineForm.payAmount;
      } else {
        this.ficenum = this.offlineForm.priceDetailList
          .reduce((pre, cur) => {
            return pre + (cur.goodsPrice * (cur.number * 10)) / 10;
          }, 0)
          .toString();
        if (this.ficenum.toString().indexOf(".") != -1) {
          this.ficenum = this.ficenum
            .toString()
            .substring(0, this.ficenum.toString().indexOf(".") + 5);
        } else {
          this.ficenum = this.ficenum;
        }
      }
    },
    handleReceivingFileView(type) {
      if (
        (type == 1 && this.receivingInvoiceUrl1.length == 0) ||
        (type == 2 && this.inspectFile1.length == 0)
      ) {
        this.$message.error("请上传文件");
        return;
      }
      this.receivingFiles =
        type == 1 ? this.receivingInvoiceUrl1 : this.inspectFile1;
      this.receivingFileView = true;
    },
    returnSign() {
      try {
        this.$refs.returnSignUpload.clearFiles();
        this.returnSignFileList = [];
      } catch (error) {}
      this.returnSignDiolog = true;
    },
    exportSignData() {
      let config = {
        orderId: this.$route.query.orderId,
        page: 1,
        size: 1000000,
      };
      exportSign(config).then((res) => {
        const blob = new Blob([res], {
          // 创建一个新的Blob对象来接收后端的文件,这里第一个参数必须是数组类型，否则下载必出错。
          type: "application/vnd.ms-excel", // type，表明该 Blob 对象所包含数据的 MIME 类型,这需要前后端统一规划
        });
        let link = document.createElement("a");
        let body = document.querySelector("body");
        link.href = window.URL.createObjectURL(blob); //
        link.style.display = "none"; // 让这个a标签不可见
        link.download = "结算数据表"; //文件名称
        body.appendChild(link);
        link.click(); // 创建了新的a标签之后模拟点击事件，开始传输文件
        body.removeChild(link); // 下载完成之后，移除按钮，垃圾回收，减少页面内存消耗
        window.URL.revokeObjectURL(link.href); // 移除之前使用createObjectURL创建的URL，垃圾回收
      });
    },
    getOrderDetail() {
      orderDetail({ orderId: this.$route.query.orderId }).then((res) => {
        if (res.code == 0) {
          this.tradeGoods = res.data?.tradeGoods || {};
          this.pageData = res.data || {};
          if (res.data.orderCategoryList) {
            this.goodsCategoryList = res.data.orderCategoryList.map((item) => {
              return { ...item, ...{ goodsNum: res.data.tradeGoods.goodsNum } };
            });
          }
          // if (
          //   this.tradeGoods.ifAdjustPrice == 1 &&
          //   this.tradeGoods.tradeType == 1
          // ) {
          //   this.handleViewPriceHistory();
          // }
        }
      });
    },
    getPrepaymentBalance() {
      let config = {
        orderId: this.$route.query.orderId,
      };
      getPrepaymentBalance(config).then((res) => {
        if (res.code == 0) {
          this.prepaymentBalance = res.data.prepaymentBalance;
        }
      });
    },
    btnClick() {
      let config = {
        orderId: this.$route.query.orderId,
        searchType: 1,
      };
      getAccountBalanceByParam(config).then((res) => {
        if (res.code == 0) {
          this.balances = res.data;
          this.dialogofflineForm = true;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    setactiveName() {
      let enterpriseId = Vue.ls.get(types.enterpriseId);
      let userId = Vue.ls.get(types.userId);
      if (enterpriseId != userId) {
        let obj = [];
        if (
          !this.beforhandle("personalCenter:orderInfo:detail:contract", "1")
        ) {
          this.firstDisabled = true;
        } else {
          // this.activeName = "first";
          obj.push({
            name: "hetong",
            value: "first",
          });
        }
        if (
          !this.beforhandle("personalCenter:orderInfo:detail:signature", "1")
        ) {
          this.threeDisabled = true;
        } else {
          if (this.contrac.contractType == 2) {
            obj.push({
              name: "qianzhang",
              value: "three",
            });
            // this.getContractState();
            this.getorder();
          }
        }
        if (!this.beforhandle("personalCenter:orderInfo:detail:pay", "1")) {
          this.thirdDisabled = true;
        } else {
          obj.push({
            name: "zhifu",
            value: "third",
          });

          if (
            (this.contrac.signatureStatus == null
              ? this.contrac.contractStatus != 3
              : this.contrac.signatureStatus != 3) == false
          ) {
            this.paymentpage();
          }
        }
        if (
          !this.beforhandle("personalCenter:orderInfo:detail:transport", "1")
        ) {
          this.fourthDisabled = true;
        } else {
          obj.push({
            name: "yunshu",
            value: "fourth",
          });

          if (
            (this.contrac.signatureStatus == null
              ? this.contrac.contractStatus != 3
              : this.contrac.signatureStatus != 3) == false
          ) {
            this.tralists();
          }
        }
        if (
          !this.beforhandle("personalCenter:orderInfo:detail:settlement", "1")
        ) {
          this.fiveDisabled = true;
        } else {
          obj.push({
            name: "jiesuan",
            value: "five",
          });

          if (
            (this.contrac.signatureStatus == null
              ? this.contrac.contractStatus != 3
              : this.contrac.signatureStatus != 3) == false
          ) {
            this.signgetlist();
          }
        }
        if (!this.beforhandle("personalCenter:orderInfo:detail:billing", "1")) {
          this.secondDisabled = true;
        } else {
          obj.push({
            name: "kaipiao",
            value: "second",
          });

          if (
            (this.contrac.signatureStatus == null
              ? this.contrac.contractStatus != 3
              : this.contrac.signatureStatus != 3) == false
          ) {
            this.invoicepage();
          }
        }

        return;
        this.activeName = obj[0].value;
      }
    },
    getparentLevel() {
      getCompanyAuthDetail().then((res) => {
        this.numberValidateForms.name = res.data.companyName;
        this.numberValidateForms.card_id = res.data.socialCode;
      });
    },
    closeChan() {
      this.receivingForm = {
        signCount: "", //签收数量
        inspectUrl: [], //煤炭检验单访问地址
        invoiceUrl: [], //结算单据
        //receiptUrls: [], //电厂收货单访问地址
        settleWay: 1,
        qualityDocumentList: [],
        settleDocumentList: [],
        settleDetailList: [
          {
            categoryId: "",
            number: "",
            goodsPrice: "",
            key: 0,
          },
        ],
      };
    },
    closeChang() {
      this.transport = {
        trafficCount: "",
        startPlace: "",
        trafficWay: "",
        destPlace: "",
        contacts: "",
        contactInfo: "",
        fileIds: [],
        trafficDetailList: [
          {
            categoryId: "",
            number: "",
            goodsPrice: "",
            key: 0,
          },
        ],
      };
      this.fiel = [];
      this.transport.fileIds = [];
      this.ransportviews = [];
    },
    delImg() {
      this.fiel = [];
      this.transport.fileIds = [];
      this.ransportviews = [];
    },
    getContractState() {
      let paramObj = {
        orderId: this.$route.query.orderId,
      };
      getContractState(paramObj)
        .then((res) => {
          if (res.code == 0) {
            this.contractState = res.data.signatureStatus;
            this.annexUrls = res.data.annexInfo.contractUrls || [];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeDialogopreview(param) {
      this.dialogopreview = param;
      this.getorder();
      // this.getContractState();
    },
    async getList() {
      const res = await serviceGoodCollection(this.formInline);
      if (res.code == 0) {
        this.tableList = res.data.list;
      }
    },
    getGoodCollection() {
      let id = Vue.ls.get(types.enterpriseId);
      let config = {
        dictId: 506,
        name: "",
        pageNum: 1,
        pageSize: 1000,
        userId: id,
      };
      serviceGoodCollection(config).then((res) => {
        if (res.code == 0) {
          if (res.data.total > 0) {
            this.tableList = res.data.list;
            this.productlist = true;
            this.transportations = false;
          } else {
            console.log("没有关注的服务商");
            this.$confirm(
              "您还未关注物流服务商品，请先前往服务大厅进行关注！",
              "提示",
              {
                confirmButtonText: "立即前往",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.beforeClose();
                this.$router.push({
                  path: "/financial",
                  query: {
                    id: 14,
                    title: "服务大厅",
                  },
                });
              })
              .catch(() => {
                this.transportations = false;
              });
          }
        }
      });
    },
    invoiceUrlVisFlse() {
      // this.form.invoiceUrlList=[]
      this.invoiceUrlVis = false;
    },
    accountClickList(row) {
      this.form.invoiceUrlList = row.invoiceUrlList;
      this.invoiceUrlVis = true;
    },
    // 服务商运输
    carRiages() {
      this.transports.startPlace = this.transports.startPlace
        .toString()
        .replace(new RegExp(",", "gm"), "-");
      this.transports.destPlace = this.transports.destPlace
        .toString()
        .replace(new RegExp(",", "gm"), "-");
      this.$refs.transports.validate((valid) => {
        if (valid) {
          this.getGoodCollection();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    beforeClose() {
      this.$refs.transports.resetFields();
      this.transportations = false;
    },
    transmitClick(item) {
      let config = JSON.parse(JSON.stringify(this.transports));
      config.orderId = this.$route.query.orderId;
      config.trafficType = 2;
      config.goodsName = this.goodsName;
      config.trafficDetailList = this.isBidding
        ? this.transports.trafficDetailList
        : [];
      if (this.isBidding) {
        config.trafficCount = config.trafficDetailList.reduce((pre, cur) => {
          return pre + Number(cur.number);
        }, 0);
      }
      addtrafficpage(config).then((res) => {
        if (res.code == 0) {
          let _config = {
            tradeTrafficId: res.data.trafficId,
            serviceGoodId: item.serviceGoodId,
          };
          placeTransportOrderFromTrade(_config).then((ret) => {
            if (ret.code == 0) {
              this.$message({
                message: "发起成功",
                type: "success",
              });
              this.productlist = false;
              this.beforeClose();
              this.tralists();
              this.transports = {
                trafficCount: "", //运输数量
                trafficPrice: "", //运输单价
                goodsName: "", //商品名称
                payWay: "", //付款类型
                contacts: "", //联系人
                contactInfo: "", //联系方式
                startPlace: "", //起始地
                destPlace: "", //目的地
                trafficDetailList: [
                  {
                    categoryId: "",
                    number: "",
                    goodsPrice: "",
                    key: 0,
                  },
                ],
              };
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getGoodsName() {
      reqDictType({
        dictType: "coal_type",
      })
        .then((ret) => {
          if (ret.code == 0) {
            this.dictList = JSON.parse(JSON.stringify(ret.data.children));
            let data = ret.data.children;
            for (let i = 0; i < data.length; i++) {
              let item = data[i];
              for (let j = 0; j < item.children.length; j++) {
                let sub = item.children[j];
                delete sub.children;
              }
            }
            this.nameList = data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goodsChange(e) {
      this.transport.goodsName = e[1];
      outer: for (let i = 0; i < this.dictList.length; i++) {
        let item = this.dictList[i];
        for (let j = 0; j < item.children.length; j++) {
          let sub = item.children[j];
          if (item.id == e[0] && sub.id == e[1]) {
            sub.children.forEach((item) => {
              this.$set(item, "quotaValue", "");
            });
            this.goodsName = sub.dictName;
            this.formData.quotaList = sub.children.map((item) => {
              let arr = item.dictValue.split(";");
              return {
                ...item,
                dictName: item.dictName + "(" + arr[0] + ")" + arr[2],
                dictValue: arr[1],
              };
            });
            break outer;
          }
        }
      }
    },
    trafficDocUrlsClick(item) {
      this.istrafficDocUrls = true;
      this.trafficDocUrlslist = item;
    },
    //发起开票选择开票信息
    addsettleUrlres(file) {
      let isPass = this.checkPic(file);
      if (isPass) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFile(formData).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.settleUrls = res.data;
            // this.$refs.invoiceForm.validateField(["settleUrl"]);
          }
        });
      }
    },
    // carRiageres
    carRiageres() {
      let config = {
        signId: this.signId,
        settleDocument: this.settleUrls,
      };
      if (this.settleUrls == "") {
        this.$message.error("请上传结算单据");
      } else {
        confirmSettle(config).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "已确认收货",
              type: "success",
            });
            this.receivingVisiblebel = false;
            this.signgetlist();
          } else {
            this.$message.error(res.data);
          }
        });
      }
    },
    // 确认发货
    ipmentsClick() {
      let config = {
        orderId: this.$route.query.orderId,
        deliverCount: this.deliverCount,
      };
      confirmDeliver(config).then((res) => {
        if (res.code == 0) {
          this.isshipments = false;
          this.signgetlist();
        }
      });
    },
    inspectpreviews() {
      this.receivingVisibless = true;
    },
    handleChangess(file, fileList) {
      this.inspectfileLists = fileList.slice(-1000);
    },
    noteconfirm(row) {
      let config = {
        invoiceId: row.invoiceId,
      };
      confirmInvoice(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "确认成功",
            type: "success",
          });
          this.invoicepage();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    examinecontract(item) {
      //ie兼容处理
      getContractOSSFile(item, {
        responseType: "blob",
      }).then((res) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(res, item);
        } else {
          // for Non-IE
          let objectUrl = URL.createObjectURL(res);
          let link = document.createElement("a");
          link.href = objectUrl;
          link.setAttribute("download", item);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }
      });
    },
    handleExceedel(files, fileList) {
      this.$message.warning(
        `当前限制选择 1000 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    handleExceeds(files, fileList) {
      this.$message.warning(`当前限制选择 10 个文件!`);
    },
    handleChangerel(file, fileList) {
      this.inspectfileList = fileList.slice(-1000);
    },
    handleChangeContract(file, fileList) {
      if (file.raw.type && file.raw.type == "application/pdf") {
        this.fileList1 = fileList.slice(-1000);
      }
    },
    handleExceedContract(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    handleExceedss(files, fileList) {
      this.$message.warning(
        `当前限制选择 1000 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },

    handleExceeds1(files, fileList) {
      this.$message.warning(`当前限制上传 1 个文件!`);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1000 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    accountClickss(row) {
      this.contimgs = row.contractUrls;
      this.isaccount = true;
    },
    // 上传合同
    beforeAvatarUpload(file) {
      let isJPG;
      if (file.type === "application/pdf") {
        isJPG = true;
      } else {
        isJPG = false;
        this.$message.error("请上传pdf文件!");
      }
      if (!isJPG) {
        return false;
      }
    },
    handleChange(file, fileList) {
      if (file.raw.type && file.raw.type == "application/pdf") {
        this.fileList = fileList.slice(-1000);
      }
    },
    handleChanges(file, fileList) {
      this.inspectfileList = fileList.slice(-1000);
    },
    beforeRemoves(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeRemove(file) {
      if (file.raw && file.raw.type == "application/pdf") {
        return this.$confirm(`确定移除 ${file.name}？`);
      }
    },
    removeAnnexFile(file, fileList) {
      this.isAnnex1 = fileList.length == 0;
    },
    //保存合同地址
    addcontract(type) {
      let typeList = {
        1: "imglist",
        2: "imglist1",
        3: "annexList",
        4: "annexList1",
      };
      if (this[`${typeList[type]}`].length == 0) {
        this.$message.error("请上传合同!");
      } else {
        let name = this[`${typeList[type]}`][0].storageName;
        let ids = [];
        let contractType;
        if (type == 1 || type == 3) {
          contractType = 1;
          for (let i = 0; i < this[`${typeList[type]}`].length; i++) {
            ids.push(this[`${typeList[type]}`][i].fileId);
          }
        }
        if (type == 2 || type == 4) {
          contractType = 3;
          for (let i = 0; i < this[`${typeList[type]}`].length; i++) {
            ids.push(this[`${typeList[type]}`][i].storageName);
          }
        }
        let ifAnnex;
        if (type == 1 || type == 2) {
          ifAnnex = 0;
        }
        if (type == 3 || type == 4) {
          ifAnnex = 1;
        }
        let config = {
          contractUrls: ids,
          orderId: this.$route.query.orderId,
          contractName: name,
          contractType: contractType,
          ifAnnex: ifAnnex,
        };
        saveContractUrl(config).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            let refList = {
              1: "upload",
              2: "uploadContract",
              3: "annexUpload1",
              4: "annexUpload3",
            };
            if (type == 4) {
              try {
                this.$refs[`${refList[3]}`].clearFiles();
              } catch (error) {}
            }
            this.$refs[`${refList[type]}`].clearFiles();
            this.annexUploadDiolog = false;
            this.getorder();
          } else {
            this.$message(res.message);
          }
        });
      }
      this[`${typeList[type]}`] = [];
    },
    fileExceed() {
      this.$message.error("上传文件数量超过限制");
    },
    removeInspectFile(file, fileList) {
      this.inspectFile1 = this.inspectFile1.filter((item) => {
        return item.originalName != file.name;
      });
    },
    handleReceivingInvoiceUrlFileChange(file, fileList) {
      this.receivingInvoiceUrl = fileList.slice(-1000);
    },
    handleAnnex() {
      try {
        this.$refs.annexUpload1.clearFiles();
      } catch (error) {}
      try {
        this.$refs.annexUpload3.clearFiles();
      } catch (error) {}
      this.annexList = [];
      this.annexList1 = [];
      this.annexUploadDiolog = true;
    },
    beforeUpload(file) {
      let acceptList = ["png", "jpg", "pdf"];
      // 根据文件名获取文件的后缀名
      let fileType = file.name.split(".").pop().toLowerCase();
      // 判断文件格式是否符合要求
      if (acceptList.indexOf(fileType) === -1) {
        this.$message.error("只能上传 png/jpg/pdf 格式的文件 !");
        return false;
      }
      // 判断文件大小是否符合要求
      // if (file.size / 1024 / 1024 > 1) {
      //   this.$message.error("上传文件大小不能超过 1M !");
      //   return false;
      // }
    },
    accountClicksss() {
      this.isaccounts = true;
    },
    handleImageAnnexUpload(file) {
      let filelds = [];
      let otherFiles = file.file;
      var formData = new FormData();
      // 文件对象
      formData.append("file", otherFiles);
      ossUploadFiletwo(formData).then((res) => {
        if (res.code == 0) {
          filelds.push(res.data.fileId);
          let config = {
            contractId: this.contractId,
            fileIds: filelds,
          };
          uploadContractAnnex(config).then((ret) => {
            if (ret.code == 0) {
              this.$message({
                message: "上传成功",
                type: "success",
              });
              this.getContractState();
            } else {
              this.$message.error(ret.message);
            }
          });
        }
      });
    },
    handleImageBeforeUpload(file, type) {
      let otherFiles = file.file;
      var formData = new FormData();
      formData.append("file", otherFiles);
      ossUploadFiletwo(formData).then((res) => {
        if (res.code == 0) {
          if (type == 1) {
            this.imglist.push(res.data);
          }
          if (type == 2) {
            this.imglist1.push(res.data);
            this.addcontract(type);
          }
          if (type == 3) {
            this.annexList.push(res.data);
          }
          if (type == 4) {
            this.annexList1.push(res.data);
            this.addcontract(type);
          }
          if (type == 5) {
            this.priceList.push(res.data);
          }
          if (type == 6) {
            this.returnSignFileList.push(res.data);
          }
          if (type == 7) {
            this.receivingInvoiceUrl1.push(res.data);
          }
          if (type == 8) {
            this.inspectFile1.push(res.data);
          }
          this.$message({
            message: res.message,
            type: "success",
          });
        }
      });
    },
    handleSelectionChange(selection) {
      this.radio = selection[0];
      if (selection.length > 1) {
        this.$refs.tb.clearSelection();
        this.$refs.tb.toggleRowSelection(selection.pop());
      }
    },
    inspectpreview() {
      this.receivingVisibles = true;
    },
    copyFn(value) {
      let oInput = document.createElement("textarea");
      oInput.value = value;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message.success("复制成功");
    },
    blurFormatFloat1(model, key, type) {
      if (type == "price") {
        model[key] = model[key] == "0." || model[key] == "0" ? "" : model[key];
      }
    },
    formatFloat1(model, key, type, fomeType) {
      if (type == "count") {
        model[key] =
          model[key]
            .replace(/[^\d^\.]+/g, "")
            .replace(/^0+(\d)/, "$1")
            .replace(/^\./, "0.")
            .match(/^\d*(\.?\d{0,4})/g)[0] || "";
      } else {
        model[key] =
          model[key]
            .replace(/[^\d^\.]+/g, "")
            .replace(/^0+(\d)/, "$1")
            .replace(/^\./, "0.")
            .match(/^\d*(\.?\d{0,2})/g)[0] || "";
      }
      if (fomeType == 4) {
        this.signCountTotal = this.receivingForm.settleDetailList.reduce(
          (pre, cur) => {
            return pre + Number(cur.number);
          },
          0
        );
        this.settleAmountTotal = this.receivingForm.settleDetailList.reduce(
          (pre, cur) => {
            return pre + (cur.goodsPrice * (cur.number * 10)) / 10;
          },
          0
        );
      }
      if (fomeType == 1) {
        if (key == "payAmount") {
          this.ficenum = model[key];
        }
        if (this.offlineForm.payBrand == 0) {
          this.ficenum = this.offlineForm.priceDetailList
            .reduce((pre, cur) => {
              return pre + (cur.goodsPrice * (cur.number * 10)) / 10;
            }, 0)
            .toString();
          if (this.ficenum.toString().indexOf(".") != -1) {
            this.ficenum = this.ficenum
              .toString()
              .substring(0, this.ficenum.toString().indexOf(".") + 5);
          } else {
            this.ficenum = this.ficenum;
          }
        }
      }
    },
    formatFloat(name) {
      if (name == "payPrice") {
        if (this.$route.query.ifInvite == 1) {
          this.offlineForm[name] =
            this.offlineForm[name]
              .replace(/[^\d^\.]+/g, "")
              .replace(/^0+(\d)/, "$1")
              .replace(/^\./, "0.")
              .match(/^\d*(\.?\d{0,2})/g)[0] || "";
          if (this.offlineForm[name].indexOf(".") < 0) {
            this.offlineForm[name] = this.offlineForm[name].substring(0, 5);
          } else {
            this.offlineForm[name] =
              this.offlineForm[name].indexOf(".") >= 5
                ? this.offlineForm[name].substring(0, 5) +
                  this.offlineForm[name].substring(
                    this.offlineForm[name].indexOf(".")
                  )
                : this.offlineForm[name];
          }
        } else {
          // this.offlineForm[name] =
          //   this.offlineForm[name]
          //     .replace(/[^\d]/g, "")
          //     .match(/^\d*(\.?\d{0,23})/g)[0] || "";
          this.offlineForm[name] =
            this.offlineForm[name]
              .replace(/[^\d^\.]+/g, "")
              .replace(/^0+(\d)/, "$1")
              .replace(/^\./, "0.")
              .match(/^\d{0,5}(\.?\d{0,2})/g)[0] || "";
        }
      } else {
        this.offlineForm[name] =
          this.offlineForm[name].match(/^\d*(\.?\d{0,23})/g)[0] || "";
      }
      if (name == "payCount") {
        if (this.offlineForm[name].indexOf(".") > 0) {
          if (this.offlineForm[name] * 10 <= 1) {
            if (this.offlineForm[name] == "0.0") {
              this.offlineForm[name] = 0;
            }
            if (this.offlineForm[name] == "0.10") {
              this.offlineForm[name] = "0.1";
            }
          } else {
            this.offlineForm[name] = this.offlineForm[name].substring(
              0,
              this.offlineForm[name].indexOf(".") + 5
            );
          }
        } else {
          this.offlineForm[name] = this.offlineForm[name].substring(0, 7);
        }
        let a =
          (this.offlineForm.payPrice * (this.offlineForm.payCount * 10)) / 10;
        let b = a.toString();
        if (b.indexOf(".") != -1) {
          this.ficenum = b.substring(0, b.indexOf(".") + 5);
        } else {
          this.ficenum = b;
        }
      }
      if (name == "payPrice") {
        if (this.offlineForm[name].indexOf(".") > 0) {
          this.offlineForm[name] = this.offlineForm[name].substring(
            0,
            this.offlineForm[name].indexOf(".") + 5
          );
        }
        let a =
          (this.offlineForm.payPrice * (this.offlineForm.payCount * 10)) / 10;
        let b = a.toString();
        if (b.indexOf(".") != -1) {
          this.ficenum = b.substring(0, b.indexOf(".") + 5);
        } else {
          this.ficenum = b;
        }
      }
    },
    formInvoiceAmount(e) {
      // this.form.invoiceAmount = e.target.value;
    },
    formInvoiceCount(e) {
      // this.form.invoiceCount = e.target.value;
    },
    offlineFormPayPrice(e) {
      this.offlineForm.payPrice = e.target.value;
    },
    offlineFormPayCount(e) {
      this.offlineForm.payCount = e.target.value;
    },
    transportContactInfo(e) {
      this.transport.contactInfo = e.target.value;
    },
    transportContactInfos(e) {
      this.transports.contactInfo = e.target.value;
    },
    receivingFormSignCountss(e) {
      this.transports.trafficCount = e.match(/^[0-9]+(\.[0-9]{1,3})?$/g) || "";
      if (e.indexOf(".") > 0) {
        this.transports.trafficCount = e.substring(0, e.indexOf(".") + 5);
      } else {
        this.transports.trafficCount = e;
      }
    },
    receivingFormSignCount(name) {
      if (name == "signCount") {
        this.receivingForm[name] =
          this.receivingForm[name].match(/^\d*(\.?\d{0,23})/g)[0] || "";
        if (this.receivingForm[name].indexOf(".") > 0) {
          this.receivingForm[name] = this.receivingForm[name].substring(0, 13);
        } else {
          this.receivingForm[name] = this.receivingForm[name].substring(0, 9);
        }
      }
      if (name == "invoiceCount" || name == "invoiceAmount") {
        this.form[name] = this.form[name].match(/^\d*(\.?\d{0,23})/g)[0] || "";
        if (this.form[name].indexOf(".") > 0) {
          this.form[name] = this.form[name].substring(
            0,
            this.form[name].indexOf(".") + 5
          );
        }
      } else if (name == "trafficCount") {
        // console.log(this.transport[name]);
        // this.transport[name] =
        //   this.transport[name].match(/^[0-9]+(\.[0-9]{1,3})?$/g)[0] || "";
        if (this.transport[name].indexOf(".") < 0) {
          if (this.transport[name].length <= 7) {
            return;
          } else {
            if (this.transport[name].length > 7) {
              this.transport[name] = this.transport[name].substring(0, 7);
            }
          }
        }
        if (this.transport[name].indexOf(".") > 0) {
          this.transport[name] = this.transport[name].substring(
            0,
            this.transport[name].indexOf(".") + 5
          );
        }
      } else {
        this.receivingForm[name] =
          this.receivingForm[name].match(/^\d*(\.?\d{0,23})/g)[0] || "";
        if (this.receivingForm[name].indexOf(".") > 0) {
          this.receivingForm[name] = this.receivingForm[name].substring(
            0,
            this.receivingForm[name].indexOf(".") + 5
          );
        }
      }
    },
    transportTrafficCount(e) {
      this.transports.trafficCount = e.target.value;
    },
    transportStartPlace(e) {
      this.transport.startPlace = e.target.value;
    },
    transportDestPlace(e) {
      this.transport.destPlace = e.target.value;
    },
    transportContacts(e) {
      this.transport.contacts = e.target.value;
    },
    transportContactss(e) {
      this.transports.contacts = e.target.value;
    },
    addVoucher(row) {
      this.paymentId = row.paymentId;
    },
    handleInspectFileFileChange(file, fileList) {
      this.inspectFile = fileList.slice(-1000);
    },
    removeInvoiceUrlFile(file, fileList) {
      this.receivingInvoiceUrl1 = this.receivingInvoiceUrl1.filter((item) => {
        return item.originalName != file.name;
      });
    },
    signDetail(id) {
      getSignDetail({ signId: id }).then((res) => {
        if (res.code == 0) {
          this.signDiolog = true;
          this.signDetailData = res.data;
        }
      });
    },
    submitReturnSign() {
      if (this.returnSignFileList.length == 0) {
        this.$message.error("请上传凭证");
        return;
      }
      let fileIds = this.returnSignFileList.map((item) => item.fileId);
      returnPrepayment({ orderId: this.$route.query.orderId, fileIds }).then(
        (res) => {
          if (res.code == 0) {
            this.$message.success("预付款退还申请成功");
            this.returnSignDiolog = false;
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    removereturnSignFile(file, fileList) {
      this.returnSignFileList = this.returnSignFileList.filter((item) => {
        return item.originalName != file.name;
      });
    },
    parentEvent(data) {
      if (this.$route.query.tradeType == 1) {
        if (data.tradeGoodsListing.listingPrice == 0) {
          this.offlineForm.payPrice = "";
          this.isoffline = false;
        } else {
          this.isoffline = true;
          this.offlineForm.payPrice = data.tradeGoodsListing.listingPrice;
        }
      } else {
        console.log(data, "kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
        // if (data.tradeBidding.dealPrice == 0) {
        //   this.offlineForm.payPrice = "";
        //   this.isoffline = false;
        // } else {
        //   this.isoffline = true;
        //   this.offlineForm.payPrice = data.tradeBidding.dealPrice;
        // }
        if (data.goodsCategoryList[0].dealPrice == 0) {
          this.offlineForm.payPrice = "";
          this.isoffline = false;
        } else {
          this.isoffline = true;
          this.offlineForm.payPrice = data.goodsCategoryList[0].dealPrice;
          this.ficenum = data.goodsCategoryList[0].dealPrice;
        }
      }
      this.data = data;
      this.sellerId = data.sellerId;
      this.offlineForm.quantity = data.tradeGoods.stockCount;
      if (data.overOrderCount == 0 || data.overOrderCount < 0) {
        this.offlineForm.payCount = 0;
        this.orderSurplusCount = 0;
      } else {
        this.offlineForm.payCount = data.overOrderCount;
        this.orderSurplusCount = data.overOrderCount;
      }
    },
    //获取合同
    getorder() {
      let orderId = this.$route.query.orderId;
      detailByOrder(orderId).then((res) => {
        if (res.code == 0) {
          this.contractId = res.data.contractId;
          this.annexId =
            res.data.annexInfo.contractStatus == -1
              ? ""
              : res.data.annexInfo.contractId;
          this.contrac = res.data;
          this.contrac.annexInfo.contractType =
            this.contrac.annexInfo?.contractType == 4
              ? 3
              : this.contrac.annexInfo?.contractType;
          // this.annexUrls = res.data.annexInfoList;
          this.annexUrls = res.data.annexInfoList
            ? res.data.annexInfoList.reduce((pre, cur) => {
                return [...pre, ...cur.contractUrls];
              }, [])
            : [];
          this.isPublisher == "true";
          if (
            res.data.signatureStatus != 3 &&
            res.data.signatureStatus != null
          ) {
            if (
              null != res.data.contractUrls &&
              res.data.contractUrls.length > 0
            ) {
              this.documentId = res.data.contractUrls[0].documentId;
            }
          } else {
            if (
              null != res.data.annexInfo.contractUrls &&
              res.data.annexInfo.contractUrls.length > 0 &&
              res.data.annexInfo.signatureStatus != 3 &&
              res.data.annexInfo.signatureStatus != null
            ) {
              this.documentId = res.data.annexInfo.contractUrls[0].documentId;
            }
          }
          this.setactiveName();
        }
      });
    },
    traderFinish(row) {
      let config = {
        confirm: row,
        orderId: this.$route.query.orderId,
      };
      finish(config).then((res) => {
        return res;
      });
    },
    // 选择模板单选
    getCurrentRow(row) {
      this.radio = row;
    },
    getOnlineContractInfo(id) {
      getOnlineContractInfo(id).then((res) => {
        this.dialogTableVisibles = true;
        if (res.code == 0) {
          this.goodsInfoList = res.data.goodsInfoList[0];
          if (res.data.onlineInfo.payType) {
            res.data.onlineInfo.payType = String(res.data.onlineInfo.payType);
          } else {
            res.data.onlineInfo.payType = "";
          }
          if (res.data.onlineInfo.transportType) {
            res.data.onlineInfo.transportType = String(
              res.data.onlineInfo.transportType
            );
          } else {
            res.data.onlineInfo.transportType = "";
          }
          if (res.data.onlineInfo.distributionType) {
            res.data.onlineInfo.distributionType = String(
              res.data.onlineInfo.distributionType
            );
          } else {
            res.data.onlineInfo.distributionType = "";
          }
          if (res.data.onlineInfo.deliveryType) {
            res.data.onlineInfo.deliveryType = String(
              res.data.onlineInfo.deliveryType
            );
          } else {
            res.data.onlineInfo.deliveryType = "";
          }
          this.onlineInfo = res.data.onlineInfo;
        }
      });
    },
    onSubmit() {
      //  goodsInfoList
      this.$refs["onlineInfo"].validate((valid) => {
        if (valid) {
          this.$refs["goodsInfoList"].validate((val) => {
            if (val) {
              const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
              });
              let operateType;
              if (this.$route.query.isPublisher) {
                operateType = 1;
              } else {
                operateType = 2;
              }
              let config = {
                goodsInfoList: [this.goodsInfoList],
                onlineInfo: this.onlineInfo,
                operateType: operateType,
              };
              updateOnlineContractInfo(config).then((res) => {
                if (res.code == 0) {
                  this.dialogTableVisibles = false;
                  let config = {
                    orderId: this.$route.query.orderId,
                    contractId: this.contractId,
                  };
                  confirm(config).then((res) => {
                    if (res.code == 0) {
                      loading.close();
                      this.$message({
                        type: "success",
                        message: "已确认合同无误!",
                      });
                      this.getorder();
                    }
                  });
                } else {
                  this.$message.error(res.message);
                  loading.close();
                }
              });
            }
          });
        }
      });
    },
    //选择合同模板
    CurrentRowClick() {
      if (!this.radio) {
        this.$message({
          message: "请选择合同  ",
          type: "warning",
        });
        return false;
      } else if (!this.radio.contractUrl) {
        this.$message({
          message: "合同地址无效",
          type: "warning",
        });
        return false;
      }

      let contractUrls = [];

      contractUrls.push(this.radio.contractUrl);
      let config = {
        contractUrls: contractUrls,
        orderId: this.$route.query.orderId,
        contractName: this.radio.contractName,
        contractType: 2,
        ifAnnex: 0,
      };
      saveContractUrl(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.dialogTableVisible = false;
          this.getorder();
        } else {
          this.$message(res.message);
        }
      });
    },
    sendMsg(param, val, type) {
      let data = JSON.stringify(param);
      document.getElementById("topFrame").contentWindow.postMessage(data, "*");
      if (val) {
        if (type == "numberValidateForm") {
          this.Softsign = 1;
          this.signatureName = this.numberValidateForm.account;
          this.signaturePwd = this.numberValidateForm.sign_pwd;
          this.numberValidateForm.account = "";
          this.numberValidateForm.sign_pwd = "";
        } else if (type == "numberValidateForms") {
          this.Softsign = 2;
          this.signatureName = this.numberValidateForms.name;
          this.signaturePwd = this.numberValidateForms.card_id;
          this.numberValidateForms.name = "";
          this.numberValidateForms.card_id = "";
        }
        this.dialogopreview = true;
        this.dialogVisibles = false;
        this.dialogVisibless = false;
      }
    },
    numberValidateFormclose() {
      this.$refs.numberValidateForm.resetFields();
    },
    dialogVisibleClonse() {
      this.$refs.numberValidateForm.resetFields();
    },
    // 软签提交
    submitForm(val) {
      let _this = this;
      const nextStep = () => {
        this.dialogopreview = true;
        this.dialogVisibles = false;
        this.dialogVisibless = false;
      };
      if (val == "numberValidateForm") {
        this.$refs.numberValidateForm.validate((valid) => {
          if (valid) {
            this.Softsign = 1;
            this.signatureName = this.numberValidateForm.account;
            this.signaturePwd = this.numberValidateForm.sign_pwd;
            this.numberValidateForm.account = "";
            this.numberValidateForm.sign_pwd = "";
            nextStep();
          }
        });
      } else if (val == "numberValidateForms") {
        this.Softsign = 2;
        this.signatureName = this.numberValidateForms.name;
        this.signaturePwd = this.numberValidateForms.card_id;
        this.numberValidateForms.name = "";
        this.numberValidateForms.card_id = "";
        nextStep();
      }
    },
    // 设置证书形式
    setCertSource(val) {
      let _this = this;
      let param = {
        type: "setCertSource",
        cert_source: 4,
      };
      let params = {
        type: "setSealPoisiton",
        allow_drag: true,
        position_type: 3,
        coordinate_obj: {},
        seam_obj: {
          // 骑缝可拖动
          seam_type: 1,
          // y_position: 100,
          count: 4, // 印章切割数
          seal_way: 0, // 盖章页范围 0、所有页  1、奇数页  2、偶数页
        },
      };
      _this.sendMsg(param);
      _this.sendMsg(params, "signature", val);
    },
    //软签
    mildSignature() {
      this.getparentLevel();
      this.dialogVisibles = true;
      this.dialogVisible = false;
    },
    //在线签章  点击有签章
    Toobtainan() {
      this.$refs.signDownloadDrive.changeState();
      if (!this.documentId) {
        this.$message({
          type: "info",
          message: "合同地址无效",
        });
        return false;
      }
      this.dialogopreview = true;
      this.dialogVisible = false;
    },
    confirmClicks() {
      this.dialogVisible = true;
    },
    //点击无签章
    cancelClicks() {
      this.$confirm("您还没有添加签章", "提示", {
        confirmButtonText: "去添加",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          getLoginUrl().then((res) => {
            if (res.code == 0) {
              window.open(res.data, "_blank");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    inspectprevie() {
      this.receivingVisi = true;
    },
    // 上传运输单据
    addinspectUrlUrls(file) {
      let isPass = this.checkPic(file);
      if (isPass) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFiletw(formData).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.ransportviews.push(res.data.storageName);
            this.fiel.push(res.data.fileId);
            this.transport.fileIds = this.fiel;
          }
        });
      }
    },
    handleAnnex1(file, fileList) {
      if (file.raw.type && file.raw.type == "application/pdf") {
        this.fileLists = fileList.slice(-1000);
      }
    },
    // 满意点确认
    confirmClick() {
      let config = {
        orderId: this.$route.query.orderId,
      };
      finish(config).then((res) => {
        switch (res.code) {
          case -1:
            this.$confirm(res.message, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.traderFinish(true);
                this.$message({
                  message: "合同整合完成",
                  type: "success",
                });
                this.$router.go(-1);
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
            break;
          case 0:
            this.traderFinish(true);
            this.$message({
              message: "合同整合完成",
              type: "success",
            });
            this.$router.go(-1);
            break;
          case 1:
            this.$message({
              message: res.message,
              type: "error",
            });
            break;
        }
      });
    },
    receivingClickS(item) {
      let config = {
        signId: item.signId,
      };
      confirmSettle(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "已确认结算",
            type: "success",
          });
          this.signgetlist();
        } else {
          this.$message.error(res.data);
        }
      });
    },
    // 签收货物
    receivingClick() {
      if (this.receivingInvoiceUrl1.length == 0) {
        this.$message.error("请上传结算单据");
        return;
      }
      this.$refs.receivingForm.validate((valid) => {
        if (valid) {
          let config = JSON.parse(JSON.stringify(this.receivingForm));
          config.orderId = this.$route.query.orderId;
          config.settleDetailList = this.isBidding
            ? config.settleDetailList
            : [];
          config.settleWay = this.isBidding ? config.settleWay : 0;
          config.signCount = this.isBidding
            ? this.signCountTotal
            : config.signCount;
          if (this.isBidding) {
            config.settleAmount = this.settleAmountTotal;
          }
          config.settleDocumentList = this.receivingInvoiceUrl1.map(
            (item) => item.fileId
          );
          config.qualityDocumentList = this.inspectFile1.map(
            (item) => item.fileId
          );
          delete config["inspectUrl"];
          delete config["invoiceUrl"];
          confirmReceive(config).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "发起结算成功!",
              });
              this.receivingForm = {
                signCount: "", //签收数量
                inspectUrl: [], //煤炭检验单访问地址
                invoiceUrl: [], //结算单据
                //receiptUrls: [], //电厂收货单访问地址
                settleWay: 1,
                qualityDocumentList: [],
                settleDocumentList: [],
                settleDetailList: [
                  {
                    categoryId: "",
                    number: "",
                    goodsPrice: "",
                    key: 0,
                  },
                ],
              };
              this.receivingInvoiceUrl1 = [];
              this.inspectFile1 = [];
              try {
                this.$refs.uploadFileReceivingInvoice.clearFiles();
                this.$refs.uploadFileInspect.clearFiles();
              } catch (error) {}
              this.signgetlist();
              this.receivingVisible = false;
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 切换
    handleClick(tab) {
      let _this = this;
      if (tab.label == "支付管理") {
        if (!this.beforhandle("personalCenter:orderInfo:detail:pay"))
          return (this.activeName = "");
        this.paymentpage();
      } else if (tab.label == "运输管理") {
        if (!this.beforhandle("personalCenter:orderInfo:detail:transport"))
          return (this.activeName = "");
        this.tralists();
      } else if (tab.label == "结算管理") {
        if (!this.beforhandle("personalCenter:orderInfo:detail:settlement"))
          return (this.activeName = "");
        this.signgetlist();
        this.getPrepaymentBalance();
      } else if (tab.label == "开票管理") {
        if (!this.beforhandle("personalCenter:orderInfo:detail:billing"))
          return (this.activeName = "");
        _this.invoicepage();
      } else if (tab.label == "在线签章") {
        if (!this.beforhandle("personalCenter:orderInfo:detail:signature")) {
          return (this.activeName = "");
        }

        this.getorder();
        // this.getContractState();
      } else if (tab.label == "合同管理") {
        if (!this.beforhandle("personalCenter:orderInfo:detail:contract"))
          return (this.activeName = "");
      }
    },
    //线下支付
    flineFormClicks(type) {
      this.offlineForm.orderId = this.$route.query.orderId;
      this.offlineForm.payType = type;
      let priceDetailList = [];
      if (!this.isBidding) {
        this.offlineForm.payBrand = 0;
        priceDetailList = [];
        this.offlineForm.payAmount =
          (this.offlineForm.payPrice * (this.offlineForm.payCount * 10)) / 10;
        this.offlineForm.payCount = Number(this.offlineForm.payCount);
        if (this.offlineForm.payCount == 0 || this.offlineForm.payPrice == 0) {
          this.$message({
            type: "info",
            message: "支付数量/单价填写不得为0",
          });
          return false;
        }
      } else {
        this.offlineForm.payAmount = this.ficenum;
        this.offlineForm.payCount =
          this.offlineForm.payBrand != 0
            ? this.data.orderCount
            : this.offlineForm.priceDetailList.reduce((pre, cur) => {
                return pre + Number(cur.number);
              }, 0);
        priceDetailList =
          this.offlineForm.payBrand != 0
            ? []
            : this.offlineForm.priceDetailList;
      }
      let { orderId, payAmount, payBrand, payCount, payType } =
        this.offlineForm;
      this.$refs.offlineForm.validate((valid) => {
        if (valid) {
          if (type == 1) {
            addpayment({
              orderId,
              payAmount,
              payBrand,
              payCount,
              payType,
              priceDetailList,
            }).then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "支付成功!",
                });
                this.paymentpage();
                this.dialogofflineForm = false;
                this.offlineForm = {
                  payPrice: "",
                  quantity: "", //总数量
                  payAmount: "", //支付金额
                  payCount: "", //支付数量‘
                  bankType: "",
                  payBrand: "",
                  priceDetailList: [
                    {
                      categoryId: "",
                      number: "",
                      goodsPrice: "",
                      key: 0,
                    },
                  ],
                };
                this.$refs["offlineForm"].resetFields();
                // location.reload();
                if (this.$route.query.goodsId) {
                  this.$refs.parentref.getlisst(this.$route.query.goodsId);
                } else {
                  this.$refs.parentref.orderList(this.$route.query.orderId);
                }
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            let checkUkeyWhite = Vue.ls.get(types.checkUkeyWhite);
            if (checkUkeyWhite == 0) {
              ESASignClient.ESACheckIsKey(this.JsESACheckIsKeyk, null, false);
            } else {
              this.passWordIsShows = true;
            }
          }
        } else {
          return false;
        }
      });
    },
    JsESACheckIsKeyk(status, msg, retpar, params) {
      let _this = this;
      if (status == 0) {
        this.passWordIsShows = true;
      } else {
        alert(msg);
        return false;
      }
    },
    closeDialog(tag) {
      this[tag] = false;
      this.passWordIsShows = false;
    },
    submit(val) {
      let numpice =
        (this.offlineForm.payPrice * (this.offlineForm.payCount * 10)) / 10;
      let config = {
        bankType: this.offlineForm.bankType,
        payBrand: this.offlineForm.payBrand,
        orderId: this.$route.query.orderId,
        payPassword: md5(val),
        payCount: this.offlineForm.payCount,
        payAmount: this.ficenum,
        payDetailList: !this.isBidding
          ? [
              {
                categoryId: this.goodsCategoryList[0].categoryId,
                goodsPrice: this.offlineForm.payPrice,
                number: this.offlineForm.payCount,
              },
            ]
          : this.offlineForm.payBrand != 0
          ? []
          : this.offlineForm.priceDetailList,
      };
      payTradeOrder(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "支付成功",
          });
          this.passWordIsShow = false;
          this.paymentpage();
          this.dialogofflineForm = false;
          this.offlineForm = {
            payPrice: "",
            quantity: "", //总数量
            payAmount: "", //支付金额
            payCount: "", //支付数量‘
            bankType: "",
            payBrand: "",
            priceDetailList: [
              {
                categoryId: "",
                number: "",
                goodsPrice: "",
                key: 0,
              },
            ],
          };
          this.$refs["offlineForm"].resetFields();
          // location.reload();
          if (this.$route.query.goodsId) {
            this.$refs.parentref.getlisst(this.$route.query.goodsId);
          } else {
            this.$refs.parentref.orderList(this.$route.query.orderId);
          }
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    CloseChange() {
      if (this.$route.query.goodsId) {
        this.$refs.parentref.getlisst(this.$route.query.goodsId);
      } else {
        this.$refs.parentref.orderList(this.$route.query.orderId);
        this.getOrderDetail();
        this.getPrepaymentBalance();

        this.offlineForm = {
          payPrice: "",
          quantity: "", //总数量
          payAmount: "", //支付金额
          payCount: "", //支付数量‘
          bankType: "",
          payBrand: "",
          priceDetailList: [
            {
              categoryId: "",
              number: "",
              goodsPrice: "",
              key: 0,
            },
          ],
        };
        this.ficenum = 0;
        try {
          this.$refs["offlineForm"].resetFields();
        } catch (error) {}
      }
    },
    flineFormclick(title) {
      this.ficenum = 0;

      if (title == 1) {
        this.btnClick();
        this.dialogTitle = "线上支付";
        this.headertype = 2;
        if (!this.isBidding) {
          let a =
            (this.offlineForm.payPrice * this.offlineForm.payCount * 10) / 10;
          a = Math.round(a * 100000) / 100000;
          this.ficenum = a;
        }
      } else {
        this.dialogofflineForm = true;
        this.headertype = 1;
        this.dialogTitle = "线下支付";
        this.getCompany();
        if (!this.isBidding) {
          let a =
            (this.offlineForm.payPrice * this.offlineForm.payCount * 10) / 10;
          a = Math.round(a * 100000) / 100000;
          this.ficenum = a;
        }
      }
    },
    getCompany() {
      let data = {
        enterpriseId: this.sellerId,
      };
      reqCompanyAuthDetail(data).then((res) => {
        this.enterprise = res.data;
      });
    },
    //确认金额已到账
    addconfirm(row) {
      this.$confirm("确认金额到账后无法修改，请谨慎操作!", "提示", {
        confirmButtonText: "已到账",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let config = {
            paymentId: row.paymentId,
          };
          addconfirm(config).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "确认成功!",
              });
              this.paymentpage();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消确认",
          });
        });
    },
    //确认合同无误
    countersign(str) {
      // confirm
      this.$confirm(
        "请认真核对交易信息,确认后无法修改,是否前去确认合同信息?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          if (
            this.contrac.contractType == 1 ||
            this.contrac.contractType == 3 ||
            this.contrac.annexInfo.contractId
          ) {
            let config = {
              orderId: this.$route.query.orderId,
              contractId: str
                ? this.contrac.annexInfo.contractId
                : this.contractId,
            };
            confirm(config).then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "已确认合同无误!",
                });
                this.getorder();
              }
            });
          } else {
            let id = this.$route.query.orderId;
            this.getOnlineContractInfo(id);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //发起开票
    addinvoice() {
      this.$refs.invoiceForm.validate((valid) => {
        if (valid) {
          this.reqAddinvoice();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    reqAddinvoice() {
      this.form.orderId = this.$route.query.orderId;
      this.form.invoiceCount = Number(this.form.invoiceCount);
      this.form.invoiceAmount = Number(this.form.invoiceAmount);
      addinvoice(this.form).then((res) => {
        if (res.code == 0) {
          this.dialogFormVisible = false;
          this.form = {
            invoiceAmount: "",
            invoiceCount: "",
            invoiceUrl: "", //开票文件地址
            settleUrl: "", //结算文件地址
            invoiceUrlList: [],
            invoiceDetailList: [
              {
                categoryId: "",
                number: 0,
                goodsPrice: 0,
                key: 0,
              },
            ],
          };
          this.invoicepage();
        }
      });
    },
    // 签约记录
    signgetlist() {
      let config = {
        orderId: this.$route.query.orderId,
        page: 1,
        size: 1000000,
      };
      signpage(config).then((res) => {
        let list = res.data.records;
        list.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
        });
        this.signlist = list;
      });
    },
    //运输记录
    tralists() {
      let config = {
        orderId: this.$route.query.orderId,
        page: 1,
        size: 10000,
      };
      trafficpage(config).then((res) => {
        let list = res.data.records;
        list.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
        });
        this.tralist = list;
      });
    },
    selectChange(name) {
      this.$refs.transport.clearValidate(name);
    },
    selectChanges(name) {
      this.$refs.transports.clearValidate(name);
    },
    carform() {
      this.transportation = false;
      this.$refs.transport.clearValidate();
    },

    handleAnnex2(file, fileList) {
      if (file.raw.type && file.raw.type == "application/pdf") {
        this.fileLists1 = fileList.slice(-1000);
      }
    },
    //发起运输
    carRiage() {
      this.$refs.transport.validate((valid) => {
        if (valid) {
          this.transport.startPlace = this.transport.startPlace
            .toString()
            .replace(new RegExp(",", "gm"), "-");
          this.transport.destPlace = this.transport.destPlace
            .toString()
            .replace(new RegExp(",", "gm"), "-");
          this.transports.trafficType = 1;
          this.transport.orderId = this.$route.query.orderId;
          if (this.isBidding) {
            this.transport.trafficCount =
              this.transport.trafficDetailList.reduce((pre, cur) => {
                return pre + Number(cur.number);
              }, 0);
          }
          let config = JSON.parse(JSON.stringify(this.transport));
          config.trafficDetailList = this.isBidding
            ? config.trafficDetailList
            : [];
          addtrafficpage(config).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "发起成功",
                type: "success",
              });
              // this.transportation = false;
              this.carform();
              this.tralists();
              this.ransportviews = [];
              this.fiel = [];
              this.transport = {
                trafficCount: "",
                startPlace: "",
                trafficWay: "",
                destPlace: "",
                contacts: "",
                contactInfo: "",
                fileIds: [],
                trafficDetailList: [
                  {
                    categoryId: "",
                    number: "",
                    goodsPrice: "",
                    key: 0,
                  },
                ],
              };
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //支付记录
    paymentpage() {
      let config = {
        orderId: this.$route.query.orderId,
        page: 1,
        size: 100,
      };
      paymentpage(config).then((res) => {
        if (res.code == 0) {
          let list = res.data.records;
          list.forEach((item) => {
            if (item.payBrand != 0) {
              item.payCount = "";
            }
            item.createTime = item.createTime.replace("T", " ");
            if (item.payStatus == 0) {
              item.payStatus = "待支付";
            } else if (item.payStatus == 1) {
              item.payStatus = "支付成功";
            } else if (item.payStatus == 2) {
              item.payStatus = "支付失败";
            }
            if (item.payType == 0) {
              item.payType = "未知";
            } else if (item.payType == 1) {
              item.payType = "线下支付";
            } else if (item.payType == 2) {
              item.payType = "线上支付";
            } else if (item.payType == 3) {
              item.payType = "承诺付款";
            }
            item.payPrice = item.payBrand == 0 ? item.payPrice : "";
            item.payBrand =
              item.payBrand == 0
                ? "按数量支付"
                : item.payBrand == 1
                ? "预付款支付"
                : item.payBrand == 2
                ? "履约金支付"
                : "";
          });
          this.tableDatas = [];
          this.tableDatas = list;
          this.tableKey++
          console.log(this.tableDatas);
        }
      });
    },
    //开票列表
    invoicepage() {
      let config = {
        orderId: this.$route.query.orderId,
        page: 1,
        size: 1000000,
      };
      invoicepage(config).then((res) => {
        if (res.code == 0) {
          let list = res.data.records;
          list.forEach((item) => {
            item.createTime = item.createTime.replace("T", " ");
            if (item.trafficWay == 0) {
              item.trafficWay = "未知";
            } else if (item.trafficWay == 1) {
              item.trafficWay = "公路";
            } else if (item.trafficWay == 2) {
              item.trafficWay = "铁路";
            } else if (item.trafficWay == 3) {
              item.trafficWay = "公铁联运";
            } else if (item.trafficWay == 4) {
              item.trafficWay = "海运";
            }
          });
          this.tableData = list;
        }
      });
    },
    //上传图片格式大小检查
    checkPic(file) {
      file = file.file;
      let isJPG;
      if (
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/bmp" ||
        file.type === "application/pdf"
      ) {
        isJPG = true;
      } else {
        isJPG = false;
      }
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isJPG) {
        this.$message.error("请上传bmp,jpg,jpeg,png,pdf文件!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 100MB!");
        return false;
      }
      return true;
    },
    // 发起开票选择开票文件
    addinvoiceUrl(file) {
      let pass = this.checkPic(file);
      if (pass) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFile(formData).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.form.invoiceUrl = res.data;
            this.$refs.invoiceForm.validateField(["invoiceUrl"]);
          }
        });
      }
    },
    addupdateVoucher(file) {
      let pass = this.checkPic(file);
      if (pass) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFile(formData).then((res) => {
          if (res.code == 0) {
            let config = {
              voucherUrl: res.data,
              paymentId: this.paymentId,
            };
            updateVoucher(config).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "上传成功",
                  type: "success",
                });
                this.paymentpage();
              }
            });
          }
        });
      }
    },
    // 上传煤质检验单
    addinspectUrlUrl(file) {
      let pass = this.checkPic(file);
      if (pass) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFiletwo(formData).then((res) => {
          if (res.code == 0) {
            // this.$message({
            //   message: "上传成功",
            //   type: "success",
            // });
            this.receivingForm.inspectUrl = [res.data];
            console.log(this.receivingForm.inspectUrl);
            this.receivingForm.qualityDocumentList = [res.data.fileId];
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        });
      }
    },
    // 上传结算单据
    addinvoiceUrlUrl(file) {
      let isPass = this.checkPic(file);
      if (isPass) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFiletwo(formData).then((res) => {
          if (res.code == 0) {
            // this.$message({
            //   message: "上传成功",
            //   type: "success",
            // });
            console.log(res.data);
            // this.sunbmitpreview.push(res.data.storageName);
            this.receivingForm.invoiceUrl = [res.data];
            this.receivingForm.settleDocumentList = [res.data.fileId];
            this.$refs.receivingForm.validateField(["invoiceUrl"]);
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        });
      }
    },
    // 上传铁路运输订票
    addreceiptUrlUrl(file) {
      let isPass = this.checkPic(file);
      if (isPass) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFiletw(formData).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.sunbmitpreviews.push(res.data.storageName);
            this.receivingForm.receiptUrls.push(res.data.fileId);
          }
        });
      }
    },
    addreceivingUrl(file) {
      let otherFiles = file;
      var formData = new FormData();
      // 文件对象
      formData.append("file", otherFiles);
      ossUploadFile(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          // this.form.invoiceUrl = res.data;
        }
      });
    },
    //发起开票选择开票信息
    addsettleUrl(file) {
      let isPass = this.checkPic(file);
      if (isPass) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFile(formData).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.form.invoiceUrl = res.data;
            this.form.invoiceUrlList.push(res.data);
            this.$refs.invoiceForm.validateField(["invoiceUrl"]);
          }
        });
      }
    },
    // 选择合同模板
    selectTem() {
      this.dialogTableVisible = true;
      let id = Vue.ls.get(types.enterpriseId);
      let config = {
        pageNum: 1,
        pageSize: 100,
        enterpriseId: id,
        contractStatus: 2,
      };
      listTemplate(config).then((res) => {
        if (res.code == 0) {
          let list = res.data.list;
          list.forEach((item) => {
            if (item.createTime) {
              item.createTime = item.createTime.replace("T", " ");
            }
          });
          this.gridData = list;
        }
      });
    },
    // 查看合同模板
    examineClick(row) {
      if (!row) {
        this.$message.error("暂无下载地址");
      } else {
        getContractOSSFile(row, {
          responseType: "blob",
        }).then((res) => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(res, row);
          } else {
            // for Non-IE
            let objectUrl = URL.createObjectURL(res);
            let link = document.createElement("a");
            link.href = objectUrl;
            link.setAttribute("download", row);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
          }
        });
      }
    },
    // 结算票据查看
    accountClicks(row) {
      this.thepreview = row;
      this.dialogopreviews = true;
    },
    // 结算票据查看(预览)
    accountClick(row, type) {
      if (type == "settlement") {
        console.log(row);
        this.receivingFiles = row;
        this.receivingFileView1 = true;
        return;
      } else {
        if (row) {
          let a = row.split(".");
          if (a[1] == "png" || a[1] == "jpg" || a[1] == "jpeg") {
            this.preview = true;
            this.previewImage = this.baseUrl + "third/third/ossGetFile/" + row;
          } else {
            window.open(this.baseUrl + "third/third/ossGetFile/" + row);
          }
        }
      }
    },
    // 开票信息查看
    invoiceClick(row) {},
    sponsorClick() {
      this.dialogFormVisible = true;
      this.form.invoiceUrlList = [];
      // let config = {
      //   orderId: this.$route.query.orderId,
      // };
      // getNewestInvoice(config).then((res) => {
      //   if (res.code == 0) {
      //     console.log(res)
      //     this.form.invoiceCount = res.data?.invoiceCount||"";
      //     this.form.invoiceAmount = res.data?.invoiceAmount||"";
      //   }
      // });
    },
    // 发起开票时上传结算票据
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    // 发起运输
    carriageClick() {
      this.transportation = true;
      if (this.$refs.transport) {
        this.$refs.transport.clearValidate();
      }
      this.transport.trafficDetailList = [
        {
          categoryId: "",
          number: "",
          goodsPrice: "",
          key: 0,
        },
      ];
      setTimeout(() => {
        console.log(this.transport);
      }, 1000);
    },
    // 发起服务商运输
    carriageClicks() {
      this.transportations = true;
      if (this.$refs.transports) {
        this.$refs.transports.clearValidate();
      }
      this.transport.trafficDetailList = [
        {
          categoryId: "",
          number: "",
          goodsPrice: "",
          key: 0,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.el-image__inner {
  top: 0;
  left: 0;
  transform: translate(0, 0);
}
</style>
<style lang="scss" scoped>
.el-form-item__content {
  text-align: left;
}
.goods-list,
.transport-goods-list {
  display: flex;
  align-items: center;
}
.annexUploadDiolog-content {
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  & > div:first-child {
    padding-top: 8px;
  }
}
.img-box {
  position: relative;

  .del-img {
    position: absolute;
    z-index: 100;
    font-size: 26px;
    right: -30px;
    top: 50%;
    margin-top: -15px;
    color: red;
  }
}

.sign {
  ::v-deep {
    .el-dialog__body {
      padding-top: 0;
    }
  }
}

.iframe {
  overflow: auto;
  height: 700px;
}

.sing-btns {
  display: flex;
  margin: 31px 0;

  div {
    width: 98px;
    height: 33px;
    text-align: center;
    line-height: 33px;
    border-radius: 5px;
    color: #333333;
    font-size: 14px;
    font-weight: bold;
  }

  ::v-deep .el-button {
    padding: 0;
    width: 98px;
    height: 33px;
    text-align: center;
    line-height: 33px;
    border-radius: 5px;
    color: #333333;
    font-size: 14px;
  }
}

.order_tabs {
  margin-top: 20px;
}

.pattern {
  display: flex;
  justify-content: space-around;

  div {
    margin: 50px auto;
    text-align: center;
    padding: 10px 20px;
    color: white;
    background: linear-gradient(90deg, #237dfb 0%, #49aefc 100%);
    width: 100px;
    cursor: pointer;
  }
}

.receiving {
  display: flex;
  justify-content: center;
  margin: 20px;

  .sign {
    text-align: center;
    margin: 0 20px;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    background: linear-gradient(90deg, #237dfb 0%, #49aefc 100%);
    border-radius: 5px;
    // width: 140px;
    cursor: pointer;
  }
}

.el-popover__reference {
  background: linear-gradient(90deg, #237dfb 0%, #49aefc 100%);
}

.previewIm {
  text-align: center;

  img {
    margin: 0 auto;
    width: 200px;
    height: 200px;
  }
}

.first {
  // width: 50%;
  margin: 0 auto;
}

.content {
  // width: 80%;
  margin: 20px auto;
  position: relative;
  .prepaymentBalance {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.shangchuang {
  width: 6px;
  height: 23px;
  background: linear-gradient(90deg, #237dfb 0%, #49aefc 100%);
  border-radius: 3px;
}

.uploading_all {
  display: flex;

  .unpload {
    margin-left: 10px;
    font-size: 20px;
    font-weight: bold;
  }
  .adjustPrice {
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin-left: 10px;
    ::v-deep .el-button {
      padding: 0;
    }
  }
}

.uploading {
  display: flex;
  justify-content: space-between;
}

.button_on {
  background: linear-gradient(90deg, #237dfb 0%, #49aefc 100%);
  border-radius: 5px;
  color: white;
  padding: 0 30px;
  margin-left: 10px;
}

.stencil {
  display: flex;
  margin-top: 20px;
  font-size: 16px;
  line-height: 60px;
  align-items: flex-start;

  .stencil_preview {
    font-size: 20px;
  }

  .template {
    font-size: 20px;
    padding: 20px;
    background: linear-gradient(90deg, #237dfb 0%, #49aefc 100%);
    border-radius: 5px;
    color: white;
    margin-left: 10px;
  }
}

.upload-demo {
  border-radius: 5px;
  color: white !important;
}

.invoice {
  padding: 10px 20px;
  background: linear-gradient(90deg, #237dfb 0%, #49aefc 100%);
  width: 100px;
  color: white;
  border-radius: 5px;
}

.invoices {
  background: linear-gradient(90deg, #237dfb 0%, #49aefc 100%);
  width: 100px;
  color: white;
  border-radius: 5px;
}

.dialogopreviews_item {
  margin: 0 auto;
  text-align: center;

  .item_content {
    display: flex;
    margin: 0 auto;
    margin-top: 20px;

    > :nth-child(1) {
      width: 200px;
    }
  }
}

::v-deep {
  .stencil {
    display: flex;
    line-height: 0;
    margin: 20px 0px;
  }

  .el-upload-list__item {
    width: 100px;
  }
  .priceFileUpload {
    margin-top: 10px;
    display: flex;
    .el-upload-list__item {
      width: 300px;
    }
  }
  .hy-btn {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px !important;
  }

  .el-input {
    width: 211px;
  }
  .el-input__inner {
    width: 211px;
  }
  .goods-list,
  .transport-goods-list {
    .el-input {
      width: 100%;
    }
    .el-input__inner {
      width: 100%;
    }
  }
}

::v-deep {
  .el-table {
    border: 1px solid #e5e5e5;

    th {
      background: #f7f7f7;
      border: none;
    }

    td {
      border: none;
      border-bottom: 1px dotted #e5e5e5;
    }
  }
}

.isshipments_item {
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .isshipments_item_input {
    border: 1px solid #ccc;
    margin: 0 10px;
  }
}

::v-deep .image_item {
  text-align: center;
}

::v-deep .el-icon-close::before {
  content: "";
}

.danju {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.productItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding: 13px 0px;
}

.produitem_img img {
  width: 167px;
  height: 97px;
  border-radius: 2px;
}

.onsale {
  background: #67c23a;
  border-radius: 10px;
  width: 54px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
}

::v-deep .input_size {
  .el-input {
    .el-input__inner {
      width: 100px !important;
    }
  }
}

.el-form-item {
  text-align: left;
}
</style>
